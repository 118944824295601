<template>
  <section class="interconnection_30">
    <m-video class="video_30" src="@/auto/assets/video/30/link.mp4" poster="@/auto/assets/video/30/link_poster.png" width="100%" muted ref="video"></m-video>

    <div class="font_box_30">
      <h3>突破壁垒<br />打通手机应用生态</h3>
      <p>手机应用无缝流转至车载大屏，无需安装、不费流量。配合智能好<br />用的场景引擎，主动识别用户意图，实现跨端的AI应用推荐</p>
    </div>
  </section>
</template>

<script>


export default {
  name: 'Interconnection',
  
  methods: {
    pagePin() {
      const video = this.$refs.video
      video.currentTime = 0

      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.interconnection_30',
          start: 'top top',
          end: '+=2500 top',
          pin: true,
          // markers: true
          onEnter: () => { 
            video.play()
          },
          onEnterBack: () => { 
            video.currentTime = 0
            video.play()
          },
          onLeave: () => {
            video.paused && video.pause() /** onPreload会触发该函数 */
          },
          onLeaveBack: () => {
            video.paused && video.pause()
          }
        }
      })
    },

    fontAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.font_box_30',
          start: '1500px top',
          end: '2000px top',
          scrub: 0.2,
          // markers: true,
        }
      })

      tl.addLabel('flag')
      tl.to('.video_30', { opacity: 0 }, 'flag')
      tl.to('.font_box_30', { opacity: 1, delay: 0.5 }, 'flag')
    },

    onLaunch() {
      this.pagePin()
      this.fontAnimation()
    }
  }
}
</script>

<style lang="less" scoped>
.interconnection_30 {
  height: 100vh;
  width: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;

  .video_30 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .font_box_30 {
    width: 640px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    h3 {
      font-weight: 700;
      font-size: 80px;
      line-height: 113px;
      text-align: center;
      margin: 0;

      background: linear-gradient(103.69deg, #3FFFF3 12.52%, #29B5FF 47.81%, #074DFF 84.61%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      margin: 24px 0 0 0;
    }
  }
}
</style>
