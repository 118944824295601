<template>
  <section class="gear_31">
    <div class="gear_box_31">
      <m-video class="desktop gear1_31" poster="@/auto/assets/video/31/driving_poster.jpg" src="@/auto/assets/video/31/driving.mp4" width="800px" height="500px"
      muted ref="gear_video"></m-video>

      <m-img src="@/auto/assets/video/31/p_poster.jpg" class="desktop gear2_31" />

      <m-video class="desktop gear3_31" src="@/auto/assets/video/31/p.mp4" poster="@/auto/assets/video/31/p_poster.jpg" width="800px" height="500px"
      muted loop ref="video"></m-video>
      
      <div class="font_box_31">
        <div class="fonts font1_31">
          <h3 class="special_font"><span class="special_font">挂起小窗</span></h3>
          <p>导航时打开手机应用、接听第三方视频通话，将以小窗形态打开，即用即走，尽可能降低对驾驶的干扰</p>
        </div>
        <div class="fonts font2_31">
          <h3 class="special_font">平行视窗</h3>
          <p>主动适配车机屏幕，同一个应用左右窗口分屏操作，支持动态调整比例，全面提升使用效率</p>
        </div>
        <div class="fonts font3_31">
          <h3><span class="special_font">解放双手，</span><br />跨端可见即可说</h3>
          <p>打开流转到车上的手机应用，屏幕上看到哪儿就说到哪儿，无需记忆特殊指令</p>
        </div>
      </div>

      <div class="bg" bg-url="@/auto/assets/image/31/car_model.png" v-bg></div>
    </div>

    <p class="extra">*为保障行车安全，我们建议你在驻车状态下接通视频会议</p>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Gear',
  
  methods: {
    switchGear() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.font_box_31',
          start: '1000px center',
          end: '2200px center',
          scrub: 0.1,
          // markers: true,
        }
      })

      tl.addLabel('font2')
      tl.to('.font1_31', { top: -125, rotateX: '90', opacity: 0 }, 'font2')
      tl.to('.font2_31', { top: 0, rotateX: '0', opacity: 1 }, 'font2')
      tl.from('.gear2_31', { y: 282, opacity: 0 }, 'font2')

      tl.to('.gear_31', { duration: 1 })

      tl.addLabel('font3')
      tl.to('.font2_31', { top: -125, rotateX: '90', opacity: 0 }, 'font3')
      tl.to('.font3_31', { top: 0, rotateX: '0', opacity: 1 }, 'font3')
      tl.from('.gear3_31', {
        opacity: 0, onStart: () => {
          const video = this.$refs.video
          video.play()
        } 
      }, 'font3')
    },

    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.gear_31',
          start: 'top top',
          end: '+=3000 top',
          pin: true,
          // markers: true
        }
      })
    },

    videoRunner() {
      const gear_video = this.$refs.gear_video
      gear_video && videoPlayByVisibleArea(gear_video, 0.5, false)

      const video = this.$refs.video
      video && videoPlayByVisibleArea(video)
    },

    onLaunch() {
      this.pagePin()
      this.switchGear()

      this.videoRunner()
    }
  }
}
</script>

<style lang="less" scoped>
.gear_31 {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, #000 0%, #000 50%, #17181C 51%, #17181C 100%);;
  position: relative;
  overflow: hidden;  
}

.extra {
  position: absolute;
  bottom: 60px;
  left: 60px;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.2);
}

.gear_box_31 {
  height: 1080px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .font_box_31 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-130px, -50%);
    z-index: 1;
    width: 432px;
    height: 222px;

    perspective: 500;
    -webkit-perspective: 500;
    -moz-perspective: 500;
    backface-visibility: hidden;

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #FFFFFF;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.6);
      margin: 8px 0 0 0;
    }

    .fonts {
      position: absolute;
      opacity: 0;
      transform-origin: 60% center -55px;
    }
    .font1_31 {
      top: 0;
      opacity: 1;

      .special_font {
        width: 230px;
        background: linear-gradient(82.91deg, #20C657 0%, #16E4B1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    .font2_31 {
      top: 125px;
      transform: rotateX(-90deg);

      .special_font { 
        width: 230px;
        background: linear-gradient(94.95deg, #D9224E 1.14%, #FE936C 98.72%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
       }
    }
    .font3_31 {
      top: 125px;
      transform: rotateX(-90deg);
      
      .special_font {
        width: 285px;
        background: linear-gradient(277.09deg, #DC79FF 0%, #256BFA 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .desktop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-41px, -50%);
    width: 800px;
    height: 500px;
  }

  .bg {
    position: absolute;
    top: 0;
    background-size: 2560px 1080px;
    background-position: center center;
    height: 1080px;
    width: 100vw;
  }
}
</style>
