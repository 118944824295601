<template>
  <section class="aicy_20">
    <m-video class="aicy_video_20" src="@/auto/assets/video/20/aicy.mp4" width="1920px" height="1080px"
      muted loop ref="video1"></m-video>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Aicy',
  
  methods: {
    videoRunner() {
      const video = this.$refs.video1

      video && videoPlayByVisibleArea(video)
    },

    videoAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.aicy_20',
          start: 'center center',
          end: 'bottom center',
          scrub: 0.2,
        }
      })

      tl.to('.aicy_video_20', { opacity: 0 })
    },

    onLaunch() {
      this.videoRunner()
      this.videoAnimation()
    }
  }
}
</script>

<style lang="less" scoped>
.aicy_20 {
  min-height: 1080px;
  height: 100vh;
  width: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;

  .aicy_video_20 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
