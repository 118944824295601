<template>
  <section class="desktop_map_3">
    <div class="map_content_box">
      <div class="map_title_3">
        <h2>地图桌面</h2>
        <h3>一触即达快速出发</h3>
      </div>

      <m-img class="big_map_3" src="@/auto/assets/image/3/map_first.jpg"></m-img>
      <div class="first_map_desktop_3">
        <m-img class="map_desttop_video smart_desktop_3" src="@/auto/assets/video/3/map_poster.jpg"></m-img>
        
        <m-video class="map_desttop_video smart_desktop_video2_3" poster="@/auto/assets/video/3/map_poster.jpg" src="@/auto/assets/video/3/map.mp4" width="800px" height="500px"
          muted loop ref="video2_3"></m-video>
      </div>

      <div class="map_desc_3">
        <h5>小窗模式</h5>
        <p>灵活拖动一步悬浮</p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'DesktopMap',

  data() {
    return {
      titleChange: false
    }
  },

  methods: {
    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.desktop_map_3',
          end: '+=3000',
          pin: true,
          // markers: true
        }
      })
    },

    runAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.big_map_3',
          start: 'center center',
          end: '3000px center',
          scrub: 0.1,
          // markers: true
        }
      })
      
      tl.to('.big_map_3', { y: 278, width: 820, height: 520, borderWidth: 10, borderRadius: 12 })
      
      tl.addLabel('small')
      tl.to('.first_map_desktop_3', { opacity: 1 }, 'small')
      tl.to('.big_map_3', { opacity: 0 }, 'small')
      
      tl.addLabel('move')
      tl.to('.first_map_desktop_3', { top: 130 }, 'move')
      tl.to('.map_title_3', { opacity: 0 }, 'move')
      tl.to('.smart_desktop_3', { opacity: 0 }, 'move')
      tl.to('.smart_desktop_video2_3', {
        opacity: 1,
        onComplete: () => {
          const video = this.$refs.video2_3
          video && video.play()
        }
      }, 'move')
    },
    
    switchFont() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.map_desc_3',
          start: '2200px center',
          end: '2500px center',
          scrub: 0.1,
          // markers: true
        }
      })

      tl.to('.map_desc_3', { opacity: 1 })
    },

    onLaunch() {
      this.pagePin() 

      this.runAnimation()
      this.switchFont()
    },
  }
}
</script>

<style lang="less" scoped>
.desktop_map_3 {
  height: 100vh;
  min-height: 1080px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #17181C;

  .map_content_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
  .map_title_3 {
    margin: 60px 0 50px 0;
    
    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      margin: 0;
      background: linear-gradient(82.91deg, #20C657 0%, #16E4B1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    
    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #fff;
      margin: 0;
    }
  }

  .big_map_3 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    height: 1200px;
    border: 0 solid #000;
    box-sizing: border-box;
    z-index: 1;
  }

  .first_map_desktop_3 {
    position: absolute;
    top: 278px;
    left: 50%;
    transform: translateX(-50%);
    width: 820px;
    height: 520px;
    box-sizing: border-box;
    border: 10px solid #000;
    border-radius: 12px;
    background-size: 100% 100%;
    opacity: 0;
    z-index: 1;
  }

  .map_desttop_video {
    position: absolute;
    top: 0;
  }
  .smart_desktop_3 {
    width: 800px;
    height: 500px;
  }
  .smart_desktop_video2_3 {
    opacity: 0;
  }
  
  .map_desc_3 {
    position: absolute;
    top: 729px;
    left: 50%;
    width: 448px;
    height: 88px;
    transform: translateX(-50%);
    opacity: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      width: 224px;
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      margin: 0;

      background: linear-gradient(82.91deg, #045EFF 0%, #CC8EFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      margin: 0;
      color: #fff;
    }
  }
}
</style>
