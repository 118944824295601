<template>
  <section class="intereative_extend_8">
    <div class="extend_box">
      <div class="font_box">
        <h3>全面屏手势交互，<br />一脉相承</h3>
        <p>传承 Flyme 在手机上成熟易用的经典全面屏手势操作，并且根据车机屏幕形态进化出更符合用车场景的模糊手势，搭配利落无形、自然顺手的知意动效，操作自然又流畅</p>
      </div>

      <m-video class="extend_video"  width="1200px" height="700px" muted loop ref="video1"
        poster="@/auto/assets/video/8/gesture_poster.jpg" src="@/auto/assets/video/8/gesture.mp4"></m-video>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'IntereativeExtend',
  
  methods: {
    videoRunner() {
      const video1 = this.$refs.video1

      video1 && videoPlayByVisibleArea(video1)
    },

    onLaunch() {
      this.videoRunner()
    }
  }
}
</script>

<style lang="less" scoped>
.intereative_extend_8 {
  background-color: #181a1f;
  position: relative;
  height: 1000px;
  width: 100%;
  overflow: hidden;

  .extend_box {
    width: 1920px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .font_box {
    position: absolute;
    width: 480px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #fff;
      margin: 0 0 8px 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .extend_video {
    position: absolute;
    left: 50%;
    top: 295px;
    width: 1200px;
    height: 700px;
    transform: translateX(-50%);
  }
}
</style>
