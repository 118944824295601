<template>
  <section class="skills_25">
    <div class="content_box">
      <h1 class="title title1_25">基于知识图谱的车辆手册问答</h1>
      <h1 class="title title2_25">一站式调度控制的车控管家</h1>
      <h1 class="title title3_25">路况轻松掌控的随行助理</h1>
      <h1 class="title title4_25">与你互娱互乐的亲密好友</h1>

      <ul class="icon_box">
        <li
          key="1"
          v-show="activeIndex === 1"
          class="icon_item active"
          bg-url="@/auto/assets/image/25/user_on.png"
          v-bg
          @click="clickItem(1)"
        ></li>
        <li
          key="2"
          v-show="activeIndex !== 1"
          class="icon_item"
          bg-url="@/auto/assets/image/25/user.png"
          v-bg
          @click="clickItem(1)"
        ></li>

        <li
          key="3"
          v-show="activeIndex === 2"
          class="icon_item active"
          bg-url="@/auto/assets/image/25/car_on.png"
          v-bg
          @click="clickItem(2)"
        ></li>
        <li
          key="4"
          v-show="activeIndex !== 2"
          class="icon_item"
          bg-url="@/auto/assets/image/25/car.png"
          v-bg
          @click="clickItem(2)"
        ></li>

        <li
          key="5"
          v-show="activeIndex === 3"
          class="icon_item active"
          bg-url="@/auto/assets/image/25/map_on.png"
          v-bg
          @click="clickItem(3)"
        ></li>
        <li
          key="6"
          v-show="activeIndex !== 3"
          class="icon_item"
          bg-url="@/auto/assets/image/25/map.png"
          v-bg
          @click="clickItem(3)"
        ></li>

        <li
          key="7"
          v-show="activeIndex === 4"
          class="icon_item active"
          bg-url="@/auto/assets/image/25/play_on.png"
          v-bg
          @click="clickItem(4)"
        ></li>
        <li
          key="8"
          v-show="activeIndex !== 4"
          class="icon_item"
          bg-url="@/auto/assets/image/25/play.png"
          v-bg
          @click="clickItem(4)"
        ></li>
      </ul>

      <div class="skills_box">
        <div class="skill_mask left_mask" />

        <ul
          v-for="(itemData, indexData) in data"
          :key="indexData"
          :class="`skill_item box${indexData + 1}_25`"
        >
          <li
            v-for="(item, index) in itemData"
            :key="index"
            :class="`skill_item_list list${
              index + 1 + indexData * itemData.length
            }_25`"
          >
            <span v-for="(it, idx) in item" :key="idx" class="skill">{{
              it
            }}</span>
          </li>
        </ul>

        <div class="skill_mask right_mask" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Skills',

  data() {
    return {
      activeIndex: 1,
      data: [
        [
          [
            '安全带预张紧器的作用',
            '哪几个是警告灯',
            '前排座椅如何调整',
            '我的反光背心在哪儿找',
            '三角警示牌怎么放',
            '如何添加洗涤液',
          ],
          [
            '安全背心在哪可以找到',
            '洗涤器的位置在哪里',
            '应该怎么检查轮胎',
            '安全带预张紧器装了吗',
            '为什么要经常洗车',
            '儿童安全锁的位置',
          ],
          [
            '三角警示牌哪里有',
            '怎么使用自动洗车机洗车',
            '在车内怎么解儿童安全锁',
            '警告灯的位置在哪',
            '激活智能远近光该如何做',
            '反光背心在哪儿',
          ],
        ],
        [
          [
            '把除霜功能打开',
            '后排氛围灯颜色绿色',
            '展开后视镜',
            '空调温度调到最低',
            '设置手套箱密码',
            '车窗关闭三分之一',
            '副驾靠背放平',
          ],
          [
            '风量调大一点',
            '关闭前除霜功能',
            '天窗和车窗开启',
            '有点热',
            '我要调下前后位置',
            '方向盘加热调到2档',
            '前排座椅向前调一点',
          ],
          [
            '副驾温度调到最低',
            '打开强力制冷',
            '温度再高一点',
            '切到内循环模式',
            '座椅放倒',
            '关闭座椅加热功能',
            '关闭儿童锁',
            '请检查下车况',
          ],
        ],
        [
          [
            '去我常去的地方',
            '我要去人民广场，走最便宜的路',
            '推荐附近的日本料理店',
            '导航去嘉兴，走高速',
            '绕开收费站',
            '我有没有超速',
          ],
          [
            '当前限速多少',
            '打开实时路况',
            '我现在在哪里',
            '导航地图放大',
            '地图放大',
            '看下附近的景点有哪些',
            '查询我们现在的位置',
          ],
          [
            '走最便宜的线路',
            '关上导航路线总览模式',
            '推荐万达广场附近的餐厅',
            '订万豪的房间',
            '查找沿途下一服务区的充电站',
            '沿途的天气怎么样',
          ],
        ],
        [
          [
            '来一首爱在深秋',
            '我要听摇滚音乐',
            '我想听张学友的歌',
            '我要打开热歌榜',
            '下一个视频',
            '重新播放歌曲',
          ],
          [
            '我要打开影视歌曲榜',
            '我想查找韩红的家乡',
            '再放一下这首歌',
            '这首歌叫什么名字',
            '快进',
            '帮我收藏这首歌',
            '播放U盘音乐',
          ],
          [
            '唱刘德华的忘情水',
            '关闭全民K歌',
            '帮我置顶“双节棍”',
            '跳到25集',
            '跳过片头',
            '智能画质',
            '搜索歌曲小苹果',
          ],
        ],
      ],
      tweenArr: [],
    }
  },

  methods: {
    switchSkills() {
      const activeIndex = this.activeIndex
      const arr = [1, 2, 3, 4]

      arr.forEach((item) => {
        if (item !== activeIndex) {
          this.$gsap.to(`.box${item}_25`, { opacity: 0, duration: 0.5 })
        } else {
          this.$gsap.to(`.box${activeIndex}_25`, { opacity: 1, duration: 1 })
        }
      })

      const min = (activeIndex - 1) * 3
      const max = activeIndex * 3
      this.tweenArr.forEach((tween, index) => {
        if (min <= index && index < max) {
          tween.play()
        } else {
          tween.pause()
        }
      })
    },

    switchFont() {
      ;[1, 2, 3, 4].forEach((item) => {
        this.$gsap.to(`.title${item}_25`, {
          opacity: this.activeIndex === item ? 1 : 0,
          duration: 0,
        })
      })
    },

    clickItem(index) {
      if (this.timer1) {
        return
      }
      this.timer1 = setTimeout(() => {
        this.timer1 = null
      }, 500)

      this.activeIndex = index
      this.switchSkills()
      this.switchFont()
    },

    initAnimation() {
      const runLeft = [2, 5, 8, 11]
      let num = 0
      while (num < 12) {
        num++

        const tween = runLeft.includes(num)
          ? this.$gsap.to(`.list${num}_25`, {
              right: 0,
              repeat: -1,
              repeatDelay: 1,
              duration: 4,
            })
          : this.$gsap.to(`.list${num}_25`, {
              left: 0,
              repeat: -1,
              repeatDelay: 1,
              duration: 4,
            })
        if (num > 3) {
          tween.pause()
        }

        this.tweenArr.push(tween)
      }
    },

    onLaunch() {
      this.initAnimation()
    },
  },
}
</script>

<style lang="less" scoped>
.skills_25 {
  position: relative;
  height: 1047px;
  background-color: #17181c;
  overflow: hidden;

  .content_box {
    width: 1920px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  .title {
    position: absolute;
    top: 190px;
    width: 100%;
    font-weight: 700;
    font-size: 56px;
    line-height: 79px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }

  .icon_box {
    position: absolute;
    top: 321px;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;

    .icon_item {
      width: 100px;
      height: 144px;
      background-size: 100%;
      cursor: pointer;
      list-style: none;
      margin-left: 60px;
      opacity: 0.5;

      &:nth-child(1),
      &:nth-child(2) {
        margin-left: 0;
      }

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }

  .skills_box {
    position: absolute;
    top: 537px;
    width: 100%;
    height: 360px;
  }

  .skill_item {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 360px;
    position: absolute;
    opacity: 0;

    &.box1_25 {
      opacity: 1;
    }

    .skill_item_list {
      white-space: nowrap;
      position: absolute;
      list-style: none;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 130px;
      }
      &:nth-child(3) {
        top: 260px;
      }
    }

    .list1_25 {
      left: -196px;
    }
    .list2_25 {
      right: -281px;
    }
    .list3_25 {
      left: -368px;
    }
    .list4_25 {
      left: -317px;
    }
    .list5_25 {
      right: -278px;
    }
    .list6_25 {
      left: -436px;
    }
    .list7_25 {
      left: -225px;
    }
    .list8_25 {
      right: -230px;
    }
    .list9_25 {
      left: -339px;
    }
    .list10_25 {
      left: -255px;
    }
    .list11_25 {
      right: -253px;
    }
    .list12_25 {
      left: -57px;
    }

    .skill {
      padding: 29px 44px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      font-weight: 400;
      font-size: 28.5px;
      line-height: 40px;
      color: #fff;
      display: inline-block;

      & + .skill {
        margin-left: 30px;
      }
    }
  }

  .skill_mask {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;

    &.left_mask {
      left: 0;
      background: linear-gradient(
        90deg,
        #17181c -4.09%,
        rgba(23, 24, 28, 0) 85.38%
      );
    }

    &.right_mask {
      right: 0;
      background: linear-gradient(
        -90deg,
        #17181c -4.09%,
        rgba(23, 24, 28, 0) 85.38%
      );
    }
  }
}
</style>
