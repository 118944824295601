<template>
  <section class="shared_39">
    <div class="content_box">
      <m-img class="link_font" src="@/auto/assets/image/39/flyme_link.png"></m-img>
      <div class="tbc_img_box" bg-url="@/auto/assets/image/39/tvc.png" v-bg>
        <m-img @click="showVideo" class="tvc_play_btn" src="@/auto/assets/image/39/play_btn.png"></m-img>
      </div>
    </div>

    <transition>
      <div class="video_mask" v-if="videoVisible">
        <div class="close_btn">
          <span class="btn btn1" @click="closeMask" bg-url="@/auto/assets/image/39/default.png" v-bg></span>
          <span class="btn btn2" @click="closeMask" bg-url="@/auto/assets/image/39/hover.png" v-bg></span>
        </div>

        <!-- TODO: 缺少poster -->
        <m-video class="mask_video" poster="@/auto/assets/video/39/flymelink.jpg" src="@/auto/assets/video/39/flymelink.mp4" width="70%" controls
          ref="mask_video"></m-video>
      </div>
    </transition>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Shared',

  data() {
    return {
      videoVisible: false
    }
  },
  
  methods: {
    showVideo() {
      this.videoVisible = true
      document.body.style = 'overflow: hidden;'
    },
    closeMask() {
      const video = this.$refs.mask_video
      video.pause()

      this.videoVisible = false
      document.body.style = 'overflow: inhert;'
    }
  }
}
</script>

<style lang="less" scoped>
.shared_39 {
  height: 1080px;
  width: 100%;
  background-color: #0C0C0E;
  position: relative;
  overflow: hidden;

  .content_box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    height: 100%;
  }

  .link_font {
    width: 376px;
    height: 65px;
    background-size: 100%;
    margin: 162px auto 40px auto;
    display: block;
  }

  .tbc_img_box {
    width: 1194px;
    height: 672px;
    background-size: 100%;
    margin: auto;
    position: relative;
  }

  .tvc_play_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 136px;
    height: 136px;
    cursor: pointer;
  }

  .video_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-width: 1200px;
    height: 100vh;
    z-index: 100;
    background-color: #000;

    .close_btn {
      position: absolute;
      top: 82px;
      right: 82px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      
      &:hover {
        .btn1 {
          display: none;
        }

        .btn2 {
          display: inline-block;
        }
      }
      
      .btn {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: 100%;
      }

      .btn2 {
        display: none;
      }
    }
  }

  .mask_video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
