<template>
  <section class="smart_bar_4">
    <div class="content_box">
      <div class="font_box_4">
        <h2>SmartBar</h2>
        <p>魅族手机上 SmartBar 经典的致敬与延续，信息更丰富，触控范围更大。在不同场景智能地做出最合适的变化，知你心，更懂你意</p>
      </div>

      <div class="smart_bar_box">
        <m-video class="smart_bar bar1_4" src="@/auto/assets/video/4/smartbar_01.mp4" poster="@/auto/assets/video/4/smartbar_01_poster.jpg" width="1356px" height="85px" muted loop ref="video_smart1"></m-video>
        <m-video class="smart_bar bar2_4" src="@/auto/assets/video/4/smartbar_02.mp4" poster="@/auto/assets/video/4/smartbar_02_poster.jpg" width="1356px" height="85px" muted ref="video_smart2"></m-video>
        <m-video class="smart_bar bar3_4" src="@/auto/assets/video/4/smartbar_03.mp4" poster="@/auto/assets/video/4/smartbar_03_poster.jpg" width="1356px" height="85px" muted loop ref="video_smart3"></m-video>
        <m-video class="smart_bar bar4_4" src="@/auto/assets/video/4/smartbar_04.mp4" poster="@/auto/assets/video/4/smartbar_04_poster.jpg" width="1356px" height="85px" muted ref="video_smart4"></m-video>
        
        <div class="cursor_4">
          <div class="cursor_dom_4"></div>
        </div>
      </div>


      <div class="camp_box_4">
        <m-video class="camp_video" src="@/auto/assets/video/4/camp.mp4" poster="@/auto/assets/video/4/camp_poster.jpg" width="100%" height="100%" muted loop ref="video_camp"></m-video>

        <div class="camp_font_4">
          <h3>露营空间</h3>
          <p>车辆恒温通风不下电，对外放电、户外娱乐一应俱全，让出行惬意又精致</p>
        </div>

        <div class="camp_card card_4" bg-url="@/auto/assets/image/4/card.png" v-bg></div>
      </div>

      <div class="grass_box_4">
        <m-video class="grass_video" src="@/auto/assets/video/4/grass.mp4" poster="@/auto/assets/video/4/grass_poster.jpg" width="100%" height="100%" muted loop ref="video_grass"></m-video>
      
        <div class="grass_font_4">
          <h3>休憩空间</h3>
          <p>车门自动落锁，座舱调整至舒适状态，星辰、篝火、海浪等多种自然场景搭配白噪音，为用户提供静谧沉浸的休息空间，滋养忙碌后的疲惫心灵</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'SmartBar',

  methods: {
    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.smart_bar_4',
          end: '+3800',
          pin: true,
        }
      })
    },

    fontVisible() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.font_box_4',
          start: 'top bottom',
          end: '500px bottom',
          scrub: 0.1,
        }
      })

      tl.from('.font_box_4', { opacity: 0 })
    },

    smartBarIncome() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.bar1_4',
          start: 'top 80%',
          end: '500px 80%',
          scrub: 0.3,
        }
      })

      tl.addLabel('bar')
      tl.from('.bar2_4', { x: 50 }, 'bar')
      tl.from('.bar3_4', { x: 100 }, 'bar')
      tl.from('.bar4_4', { x: 150 }, 'bar')
    },

    cursorAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.cursor_4',
          start: '400px 80%',
          end: '1200px 80%',
          scrub: 0.1,
        }
      })

      tl.from('.cursor_4', { y: 20, opacity: 0 })
      tl.to('.cursor_dom_4', { width: 48, height: 48 })
      tl.to('.cursor_dom_4', { width: 58, height: 58, opacity: 0 })

      tl.addLabel('camp')
      tl.to('.camp_box_4', { width: '100vw', height: '100vh', opacity: 1 }, 'camp')
      tl.to('.card_4', { opacity: 1 }, 'camp')
      tl.to('.camp_font_4', { opacity: 1 }, 'camp')
    },

    grassVideo() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.grass_box_4',
          start: '2300px top',
          end: '3100px top',
          scrub: 0.1,
        }
      })

      tl.to('.grass_box_4', { opacity: 1 })
    },

    videoRunner() {
      const video_camp = this.$refs.video_camp
      const video_grass = this.$refs.video_grass
      video_camp && videoPlayByVisibleArea(video_camp)
      video_grass && videoPlayByVisibleArea(video_grass)


      const video1 = this.$refs.video_smart1
      const video2 = this.$refs.video_smart2
      const video3 = this.$refs.video_smart3
      const video4 = this.$refs.video_smart4

      video1 && videoPlayByVisibleArea(video1)
      video2 && videoPlayByVisibleArea(video2)
      video3 && videoPlayByVisibleArea(video3)
      video4 && videoPlayByVisibleArea(video4)
    },

    onLaunch() {
      this.pagePin()
      this.fontVisible()
      this.smartBarIncome()
      this.cursorAnimation()
      this.grassVideo()

      this.videoRunner()
    },
  }
}
</script>

<style lang="less" scoped>
.smart_bar_4 {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #17181C;
  overflow: hidden;

  .content_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .font_box_4 {
    position: absolute;
    top: 120px;
    left: 50%;
    width: 448px;
    transform: translateX(calc(-50% - 236px));

    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #FFFFFF;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.6);
      margin: 8px 0 0 0;
    }
  }
  
  .smart_bar_box {
    position: absolute;
    top: 350px;
    left: 50%;
    transform: translateX(calc(-50% + 220px));

    .smart_bar {
      margin-bottom: 35px;
      width: 1356px;
      height: 85px;
    }
  }

  .cursor_4 {
    position: absolute;
    top: 374px;
    left: 152px;
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cursor_dom_4 {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0 0 8px 8px rgba(255, 255, 255, 0.08);
    }
  }

  .camp_box_4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 355px;
    height: 200px;
    opacity: 0;
    background-color: #17181C;
    overflow: hidden;

    .camp_video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }

    .camp_font_4 {
      width: 491px;
      position: absolute;
      top: calc(50% - 360px);
      right: 50%;
      transform: translateX(-89px);
      opacity: 0;

      h3 {
        font-weight: 700;
        font-size: 56px;
        line-height: 79px;
        color: #fff;
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.6);
        margin: 12px 0 0 0;
      }
    }

    .camp_card {
      position: absolute;
      left: 50%;
      top: calc(50% + 260px);
      transform: translateX(-50%);
      width: 1200px;
      height: 115px;
      background-size: 100% 100%;
      opacity: 0;
    }
  }

  .grass_box_4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: #17181C;

    .grass_video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }

    .grass_font_4 {
      width: 491px;
      position: absolute;
      top: calc(50% - 360px);
      right: 50%;
      transform: translateX(-89px);

      h3 {
        font-weight: 700;
        font-size: 56px;
        line-height: 79px;
        color: #fff;
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.6);
        margin: 12px 0 0 0;
      }
    }
  }
}
</style>
