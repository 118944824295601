<template>
  <section class="icon_9">
    <div class="kinetic_effect">
      <div class="effect_font_box_9">
        <h3>细微动效</h3>
        <p>生动的图标微动效，细腻的过渡动画，打造妙趣横生的动效体验，让细节更丰富，视觉更灵动</p>
      </div>
      <m-video class="effect_video_9" src="@/auto/assets/video/9/icon.mp4" poster="@/auto/assets/video/9/icon_poster.png" width="1200px" height="420px"
        muted loop ref="effect_video"></m-video>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Icon',
  
  methods: {
    startIconVideo() {
      const video = this.$refs.effect_video

      video && videoPlayByVisibleArea(video)
    },

    fontVisible() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.effect_font_box_9',
          start: 'center bottom',
          end: 'center center',
          scrub: 0.1,
        }
      })

      tl.from('.effect_font_box_9', { opacity: 0 })
    },
    
    onLaunch() {
      this.fontVisible()
      this.startIconVideo()
    }
  }
}
</script>

<style lang="less" scoped>
.icon_9 {
  background-color: #181a1f;
  position: relative;
  height: 846px;
  width: 100%;
  overflow: hidden;

  .kinetic_effect {
    position: absolute;
    width: 1200px;
    left: 50%;
    bottom: 247px;
    transform: translateX(-50%);

    .effect_font_box_9 {
      margin-left: 204px;

      h3 {
        margin: 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        color: #FFFFFF;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        width: 448px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.6);
        margin: 8px 0 72px 0;
      }
    }
  }
}
</style>
