<template>
  <section class="small_desktop_4">
    <div class="content_box">
      <div class="font_box">
        <h3 class="desktop_title_4">更多任务，更包容</h3>
        <p>通话、手机应用、小程序更多场景使用小窗轻量打开，地图/桌面<br />元素能主动进行避让，展示更完整的地图全功能</p>
      </div>
      
      <div class="desktop desktop1_4" v-bg bg-url="@/auto/assets/image/4/small_1.jpg"></div>
      <div class="desktop desktop2_4" v-bg bg-url="@/auto/assets/image/4/small_2.jpg"></div>
      <div class="desktop desktop3_4" v-bg bg-url="@/auto/assets/image/4/small_3.jpg"></div>
      <div class="desktop desktop4_4" v-bg bg-url="@/auto/assets/image/4/small_4.jpg"></div>
      <div class="desktop desktop5_4" v-bg bg-url="@/auto/assets/image/4/small_5.jpg"></div>
    </div>
  </section>
</template>

<script>


export default {
  name: 'SmallDesktop',

  methods: {
    fontChange() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.small_desktop_4',
          start: 'top top',
          end: '300px top',
          scrub: 0.2,
        }
      })

      tl.to('.desktop_title_4', { backgroundPositionX: '0%' })
    },

    imgChange() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.small_desktop_4',
          start: '300px top',
          end: '1100px top',
          scrub: 0.2,
        }
      })

      tl.addLabel('img')
      tl.to('.desktop1_4', { y: -100 }, 'img')
      tl.to('.desktop2_4', { y: -300 }, 'img')
      tl.to('.desktop3_4', { y: -150 }, 'img')
      tl.to('.desktop4_4', { y: -300 }, 'img')
      tl.to('.desktop5_4', { y: -100 }, 'img')
    },

    onLaunch() {
      this.fontChange()
      this.imgChange()
    },
  }
}
</script>

<style lang="less" scoped>
.small_desktop_4 {
  height: 1080px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #17181C;

  .content_box {
    width: 1920px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  .desktop {
    position: absolute;
    width: 273px;
    height: 486px;
    background-size: 100% 100%;
    border-radius: 8px;

    &.desktop1_4 { left: 167px; top: 307px; }
    &.desktop2_4 { left: 495px; top: 419px; }
    &.desktop3_4 { left: 824px; top: 347px; }
    &.desktop4_4 { right: 495px; top: 419px; }
    &.desktop5_4 { right: 167px; top: 307px; }
  }

  .font_box {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 516px;

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      margin: 0;
      text-align: center;

      background: linear-gradient(82.91deg, #045EFF 0%, #CC8EFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      margin: 12px 0 0 0;
    }
  }
}
</style>
