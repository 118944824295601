/*
 @author: M.Wang
 Created by preference on 2023/01/12
*/

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CSSPlugin } from 'gsap/CSSPlugin'

export default function install(vue) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CSSPlugin)
  Object.defineProperty(vue.prototype, '$gsap', {
    get() {
      return gsap
    },
  })
  return vue
}
