<template>
  <section class="weather_engine_2">
    <div class="content_box" bg-url="@/auto/assets/image/2/weather_car_model.png" v-bg>
      <div class="weather_font_2">
        <h2>如然引擎</h2>
        <p>细腻地再现一天 24 小时的流逝，从旭日初升到宁静深夜，还将现实中的阴晴雨雪照进了实况桌面，让你能即刻感受打破数字和现实的无界体验</p>
      </div>

      <div class="img_box">
        <m-video class="img_item weather_img1_2" src="@/auto/assets/video/2/alive.mp4" poster="@/auto/assets/video/2/alive_poster.jpg"
          width="800px" height="500px" muted loop ref="video1" @timeupdate="timeupdate($event)"></m-video>
      </div>
      
      <ul class="switch_tag">
        <li key="1" v-if="activeIndex === 1" class="tag_item active" bg-url="@/auto/assets/image/2/weather_tab1_hover.png" v-bg @click="clickItem(1)"></li>
        <li key="2" v-else class="tag_item" bg-url="@/auto/assets/image/2/weather_tab1.png" v-bg @click="clickItem(1)"></li>

        <li key="3" v-if="activeIndex === 2" class="tag_item active" bg-url="@/auto/assets/image/2/weather_tab2_hover.png" v-bg @click="clickItem(2)"></li>
        <li key="4" v-else class="tag_item" bg-url="@/auto/assets/image/2/weather_tab2.png" v-bg @click="clickItem(2)"></li>

        <li key="5" v-if="activeIndex === 3" class="tag_item active" bg-url="@/auto/assets/image/2/weather_tab3_hover.png" v-bg @click="clickItem(3)"></li>
        <li key="6" v-else class="tag_item" bg-url="@/auto/assets/image/2/weather_tab3.png" v-bg @click="clickItem(3)"></li>
      </ul>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'TimeEngine',

  data() {
    return {
      activeIndex: 1
    }
  },

  methods: {
    timeupdate(e) {
      const currentTime = e.target.currentTime

      if(currentTime >= 12) {
        if(this.activeIndex !== 3) {
          this.activeIndex = 3
        }
        return
      } 

      if(currentTime >= 8) {
        if(this.activeIndex !== 2) {
          this.activeIndex = 2
        }
        return
      }

      if(currentTime >= 0) {
        if(this.activeIndex !== 1) {
          this.activeIndex = 1
        }
        return
      } 
    },

    clickItem (index) {
      if(this.timer1) { return }
      this.timer1 = setTimeout(() => { this.timer1 = null }, 500)

      this.activeIndex = index
      
      const video = this.$refs.video1
      const time_map = {
        1: 0,
        2: 8,
        3: 12
      }
      video.currentTime = time_map[`${index}`]
    },

    videoRunner() {
      const video1 = this.$refs.video1

      video1 && videoPlayByVisibleArea(video1)
    },

    onLaunch() {
      this.videoRunner() /** onPreload 不执行。 */
    }
  }
}
</script>

<style lang="less" scoped>
.weather_engine_2 {
  height: 1331px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #17181C;

  .content_box {
    position: absolute;
    top: 175px;
    left: 50%;
    transform: translateX(-50%);
    width: 2560px;
    height: 100%;
    background-size: 2560px 1156px;
    background-position: center top;
  }

  .weather_font_2 {
    width: 486px;
    position: absolute;
    left: 884px;

    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #FFFFFF;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.6);
      margin: 8px 0 0 0;
    }
  }

  .img_box {
    position: absolute;
    top: 290px;
    left: 880px;
    width: 800px;
    height: 500px;

    .img_item {
      position: absolute;
      top: 0;
    }
  }

  .switch_tag {
    position: absolute;
    top: 874px;
    left: 50%;
    transform: translateX(-50%);
    width: 230px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-evenly;

    .tag_item {
      list-style: none;
      margin: 0 0 0 52px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 100% auto;

      &:nth-child(1) {
        margin-left: 0;
      }

      &.active {
        height: 65px;
      }
    }
  }
  
}
</style>
