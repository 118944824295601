<template>
  <section class="secen_helper_29">
    <div class="content_box" ref="content_box">
      <div class="font_box_29">
        <h3>场景助手</h3>
        <p>自定义你的专属座舱</p>
      </div>

      <div class="img_box img1_29" bg-url="@/auto/assets/image/29/1.jpg" v-bg></div>
      <div class="img_box img2_29" bg-url="@/auto/assets/image/29/2.jpg" v-bg></div>

      <p class="desc_29">一系列的快捷场景推荐模板，覆盖上班通勤、回家、露营等常用场景。<br />还可进行自定义，聚集你的奇思妙想，打造属于你的专属用车体验</p>
    </div>
  </section>
</template>

<script>


export default {
  name: 'SecenHelper',
  
  methods: {
    onLaunch() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.secen_helper_29',
          start: 'top center',
          end: 'bottom center',
          scrub: 0.1,
          // markers: true,
        }
      })

      tl.addLabel('img')
      tl.to('.img1_29', { right: 0 }, 'img')
      tl.to('.img2_29', { left: 0 }, 'img')
    }
  }
}
</script>

<style lang="less" scoped>
.secen_helper_29 {
  height: 1200px;
  width: 100%;
  background-color: #17181C;
  position: relative;
  overflow: hidden;

  .content_box {
    width: 1920px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .font_box_29 {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    width: 630px;

    h3 {
      font-weight: 600;
      font-size: 68px;
      line-height: 96px;
      width: 280px;
      text-align: center;
      margin: 0 auto;

      background: linear-gradient(91.68deg, #00EE9C 0%, #22FDE2 48.96%, #05C3FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      font-weight: 600;
      font-size: 68px;
      line-height: 96px;
      color: #fff;
      margin: 0;
    }
  }

  .img_box {
    position: absolute;
    width: 2016px;
    height: 240px;
    background-size: 100% 100%;
  }
  .img1_29 {
    top: 394px;
    right: 120px;
  }
  .img2_29 {
    top: 658px;
    left: 120px;
  }

  .desc_29 {
    position: absolute;
    top: 978px;
    width: 792px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
}
</style>
