/*
  @author: M.Wang
  Created by preference on 2023/01/10

  属性
  muted autoplay  自动播放
  src             视频地址
  poster          海报帧图片

  事件:
  canplay         可以播放了
  progress        进度

  doc:
  https://developer.mozilla.org/zh-CN/docs/Web/HTML/Element/video#%E4%BA%8B%E4%BB%B6
*/

import { emptyImg } from "./emptyImg"

const imgRe = /.*\.(jpe?g|png)$/i
export default {
  functional: true,
  props: {
    src: String,
    poster: String,
  },
  render(h, ctx) {
    const $props = ctx.props
    let poster = $props.poster
    if (
      process.env.NODE_ENV === 'production' &&
      imgRe.test(poster) &&
      window._webp_
    ) {
      // const srcArr = $props.src.split('.')
      const srcArr = poster.split('.')
      srcArr.pop()
      poster = [...srcArr, 'webp'].join('.')
    }


    const getParent = (ctx) => {
      if (!ctx) return null
      let parent = ctx.parent || ctx.$parent
      if ('preload_' in parent) return parent
      else return getParent(parent)
    }
    parent = getParent(ctx)

    // todo
    // 图片、视频加载优化、
    // 追踪父组件的 preload_

    ctx.data.attrs.poster = !parent || parent.preload_ ? poster : emptyImg
    if ('muted' in ctx.data.attrs) {
      ctx.data.domProps = { muted: true }
    }

    ctx.data.attrs.src = !parent || parent.preload_ ? $props.src : null
    return h('video', ctx.data)
  },
}
