import '@/assets/vue'
import Vue from 'vue'

import 'normalize.css'
import './css.less'
import { scrollHandle } from './bus_key'
import { createSection } from '@/utils'

const files = require.context('./sections/', false, /\.vue$/)
const fileNames = files.keys()
const componentNameReg = /.*\/((.*)-.*).vue/i
const componentNames = []
const components = {}
fileNames.forEach((filename) => {
  const matched = filename.match(componentNameReg)

  if (matched) {
    const component = files(filename)
    const componentName = `section${Number(matched[2].trim())}`
    components[componentName] = component.default || component
    componentNames.push(componentName)
  } else {
    console.warn(`区块组件 ${filename} 命名格式错误！`)
  }
})

componentNames.sort((a, b) => {
  const orderA = Number(a.replace('section', ''))
  const orderB = Number(b.replace('section', ''))
  return orderA - orderB
})

componentNames.forEach((name) => {
  Vue.component(name, createSection(components[name]))
})

new Vue({
  render: (h) => {
    const childs = []
    componentNames.forEach((name) => {
      childs.push(h(name, { key: name, tag: 'component' }))
    })
    return h('div', { attrs: { id: 'app' } }, childs)
  },

  beforeCreate() {
    function resetWindowSize() {
      const isScale = window.innerWidth < 1440
      const scale = isScale ? 0.8 : 1.0

      document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
          'content',
          'width=device-width,initial-scale=' +
          scale +
          ', maximum-scale=' +
          scale +
          ', minimum-scale=' +
          scale +
          ', user-scalable=no',
        )

      document.documentElement.style.fontSize = `${isScale * 10}px`
      // TODO: 兼容好，但是，存在滚动高度计算误差
      // document.documentElement.style.zoom = isScale
    }

    let isFirstResize = true // 避免默认函数触发resize函数
    resetWindowSize()

    let screenTimer
    window.addEventListener('resize', () => {
      if (isFirstResize) {
        isFirstResize = false
        return
      }

      if (screenTimer) {
        return
      }

      clearTimeout(screenTimer)
      screenTimer = setTimeout(() => {
        clearTimeout(screenTimer)
        screenTimer = null
      }, 300)

      resetWindowSize()
    })
  },

  mounted() {
    document.dispatchEvent(new Event('prerender'))
    window.addEventListener('scroll', (e) => {
      this.$event.emit(scrollHandle, e)
    })
    this.$event.init()
  },
}).$mount('#app')
