<template>
  <section class="in_car_45">
    <div class="content_box">
      <div class="font_box">
        <h5>丰富的车内生活<br />从这里开始</h5>
      </div>

      <div class="img_box_45">
        <m-img class="img_item ximalaya_45" src="@/auto/assets/image/45/ximalaya.png" />
        <m-img class="img_item wangpan_45" src="@/auto/assets/image/45/wangpan.png" />
        <m-img class="img_item book_45" src="@/auto/assets/image/45/book.png" />
        <m-img class="img_item sougou_45" src="@/auto/assets/image/45/sougou.png" />
        <m-img class="img_item bilibili_45" src="@/auto/assets/image/45/bilibili.png" />
        <m-img class="img_item aiqiyi_45" src="@/auto/assets/image/45/aiqiyi.png" />
        <m-img class="img_item shipin_45" src="@/auto/assets/image/45/shipin.png" />
        <m-img class="img_item gaode_45" src="@/auto/assets/image/45/gaode.png" />
        <m-img class="img_item qq_music_45" src="@/auto/assets/image/45/qq_music.png" />
        <m-img class="img_item kge_45" src="@/auto/assets/image/45/kge.png" />
        <m-img class="img_item baidu_map_45" src="@/auto/assets/image/45/baidu_map.png" />
        <m-img class="img_item zhifubao_45" src="@/auto/assets/image/45/zhifubao.png" />
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: 'InCar',
  
  methods: {
    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.in_car_45',
          end: '+=1500',
          pin: true
        }
      })
    },

    runAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.in_car_45',
          start: '200px center',
          end: '1500px center',
          scrub: 0.2,
        }
      })

      tl.addLabel('flag')
      tl.to('.ximalaya_45', { width: 408, height: 406, left: -418, top: -326 }, 'flag')
      tl.to('.wangpan_45', { width: 611, height: 610, left: 1791, top: -530 }, 'flag')
      tl.to('.book_45', { width: 407, height: 407, left: -908, top: 147 }, 'flag')
      tl.to('.sougou_45', { width: 261, height: 261, left: -209, top: 351 }, 'flag')
      tl.to('.bilibili_45', { width: 170, height: 170, left: 283, top: 266, opacity: 1 }, 'flag')
      tl.to('.aiqiyi_45', { width: 200, height: 200, left: 1414, top: 231, opacity: 1 }, 'flag')
      tl.to('.shipin_45', { width: 130, height: 130, left: 1278, top: 527, opacity: 1 }, 'flag')
      tl.to('.gaode_45', { width: 284, height: 284, left: 1778, top: 404 }, 'flag')
      tl.to('.qq_music_45', { width: 281, height: 282, left: 145, top: 626, opacity: 1 }, 'flag')
      tl.to('.kge_45', { width: 281, height: 282, left: 1453, top: 706, opacity: 1 }, 'flag')
      tl.to('.baidu_map_45', { width: 611, height: 610, left: -768, top: 928 }, 'flag')
      tl.to('.zhifubao_45', { width: 408, height: 408, left: 2097, top: 1051 }, 'flag')
    },

    onLaunch() {
      // this.pagePin()
      this.runAnimation()
    }
  }
}
</script>

<style lang="less" scoped>
.in_car_45 {
  height: 1080px;
  width: 100%;
  position: relative;
  background-color: #F7F8FA;
  overflow: hidden;
  
  .content_box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F7F8FA 47.4%, #ECECF5 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .font_box {
    width: 792px;

    h5 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #000000;
      margin: 0;
    }
  }

  .img_box_45 {
    position: absolute;
    width: 1920px;
    height: 100%;
  }

  .img_item {
    position: absolute;
  }
  .ximalaya_45 {
    top: 37px;
    left: 186px;
    width: 231px;
    height: 229px;
  }
  .wangpan_45 {
    top: -78px;
    right: 146px;
    width: 344px;
    height: 344px;
  }
  .book_45 {
    width: 228px;
    height: 229px;
    left: -89px;
    top: 303px;
  }
  .sougou_45 {
    width: 147px;
    height: 148px;
    left: 304px;
    top: 417px;
  }
  .bilibili_45 {
    width: 96px;
    height: 96px;
    left: 581px;
    top: 370px;
    opacity: 0.5;
  }
  .aiqiyi_45 {
    width: 113px;
    height: 112px;
    left: 1218px;
    top: 350px;
    opacity: 0.5;
  }
  
  .shipin_45 {
    width: 73px;
    height: 74px;
    left: 1142px;
    top: 517px;
    opacity: 0.1;
  }
  .gaode_45 {
    width: 160px;
    height: 160px;
    left: 1423px;
    top: 447px;
  }
  .qq_music_45 {
    width: 159px;
    height: 159px;
    left: 503px;
    top: 573px;
    opacity: 0.5;
  }
  .kge_45 {
    width: 158px;
    height: 159px;
    left: 1240px;
    top: 617px;
    opacity: 0.5;
  }
  .baidu_map_45 {
    width: 345px;
    height: 344px;
    left: -11px;
    top: 742px;
  }
  .zhifubao_45 {
    width: 229px;
    height: 230px;
    left: 1603px;
    top: 812px;
  }
}
</style>
