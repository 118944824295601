<template>
  <section class="to_be_end_7">
    <div class="to_be_end_box_7">
      <h3 class="end_title_7">应用无界，<br />一镜到底</h3>
      <p class="end_desc_7">打通应用边界，<br />3D 车模在不同场景一镜到底，无缝转场。</p>

      <m-video class="end_video" src="@/auto/assets/video/7/to_end.mp4" poster="@/auto/assets/video/7/to_end_poster.jpg" width="1044px" height="654px"
        muted loop ref="end_video"></m-video>

      <p class="end_desc2_7">车外环境元素亦能实时、实况呈现，<span class="font_weak">并根据不同场景提供常<br />用设置项，从视觉、到交互、到功能全面实况。</span></p>
    </div>

    <div class="air_box_7">
      <div class="air_content">
        <h3 class="air_title_7">如然空调，如临其境</h3>
        <p class="air_desc_7">延续 Lipro “如然之光”的理念，从视觉、听觉、知觉多维角度，打造出一个更自然、更智能的环境调<br />节体系，你只需根据冷热需求从“寒露、晨风、暖阳、壁炉”中选择理想场景，剩下的都可以交给我们</p>
      </div>

      <m-video class="air_video" poster="@/auto/assets/video/7/aircondition.jpg" src="@/auto/assets/video/7/aircondition.mp4" width="800px" height="500px"
      muted loop ref="air_video"></m-video>

      <div class="air_bg" bg-url="@/auto/assets/image/7/aircondition_model.png" v-bg></div>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'ToBeEnd_Aircondition',
  
  methods: {
    endFontShow() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.to_be_end_box_7',
          start: '200px bottom',
          end: '200px center',
          scrub: 0.5,
          // markers: true,
        }
      })

      tl.addLabel('title')
      tl.from('.end_title_7', { opacity: 0 }, 'title')
      tl.from('.end_desc_7', { opacity: 0 }, 'title')
    },

    stopEndVideo() {
      const tl_1 = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.to_be_end_box_7',
          start: 'bottom bottom',
          end: 'bottom center',
          scrub: 0.5,
          // markers: true,
        }
      })
      
      tl_1.from('.end_desc2_7', { opacity: 0 })
    },

    airFontShow() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.air_box_7',
          start: '200px bottom',
          end: '200px center',
          scrub: 0.5,
          // markers: true,
        }
      })

      tl.addLabel('title')
      tl.from('.air_title_7', { opacity: 0 }, 'title')
      tl.from('.air_desc_7', { opacity: 0 }, 'title')
    },

    stopAirVideo() {
      const tl_1 = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.air_box_7',
          start: 'bottom bottom',
          end: 'bottom center',
          scrub: 0.5,
          // markers: true,
        }
      })
      
      tl_1.from('.end_desc2_7', { opacity: 0 })
    },

    videoRunner() {
      const end_video = this.$refs.end_video
      const air_video = this.$refs.air_video

      end_video && videoPlayByVisibleArea(end_video)
      air_video && videoPlayByVisibleArea(air_video)
    },

    onPreload () {
      this.endFontShow()
      this.stopEndVideo()

      this.airFontShow()
      this.stopAirVideo()

      this.videoRunner()
    }
  }

}
</script>

<style lang="less" scoped>
.to_be_end_7 {
  position: relative;
  height: 2600px;
  background: #17181C;
  overflow: hidden;

  .to_be_end_box_7 {
    width: 1044px;
    margin: auto;
  }
  .end_title_7 {
    margin: 200px 0 36px 126px;
    font-weight: 700;
    font-size: 56px;
    line-height: 79px;
    color: #fff;
    width: 336px;
  }
  .end_desc_7 {
    margin: 0 0 36px 126px;
    width: 384px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
  }
  .end_video {
    border: 10px solid #000000;
    border-radius: 12px;
    overflow: hidden;
  }
  .end_desc2_7 {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    margin-top: 70px;
    width: 486px;
    margin: 70px 0 0 126px;
    .font_weak {
      color: rgba(255, 255, 255, 0.6)
    }
  }

  .air_box_7 {
    position: absolute;
    bottom: 0;
    height: 1090px;
    width: 100%;
  }
  .air_content {
    width: 820px;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #fff;
      margin: 0 0 30px 0;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
    }
  }
  .air_video {
    position: absolute;
    top: 290px;
    left: 50%;
    transform: translateX(-50%);
  }
  .air_bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center 100%;
  }
}
</style>
