/*
 @author: M.Wang
 Created by preference on 2023/01/12
*/
import { getScrollTop } from '@/utils'
import Vue from 'vue'

const eventBus = new Vue()
let resizeHandles = []
let scrollHandles = {}
export default function install(vue) {
  let isInit = !1
  Object.defineProperty(vue.prototype, '$event', {
    get() {
      return {
        init() {
          if (isInit) return
          isInit = !0
          const resizeEventName = 'window:resize'
          const scrollEventName = 'window:scroll'
          let windowHeight = Number(window.innerHeight).toFixed()
          let preloadHeight = Number((windowHeight * 2.5).toFixed())

          window.addEventListener(resizeEventName, () => {
            innerHeight = window ? Number(window.innerHeight).toFixed() : 0
            scrollHandles = {}
            resizeHandles.forEach((cb) => {
              cb()
            })
          })

          const preloadHander = () => {
            const preloadMax = getScrollTop() + preloadHeight
            const handleKeys = Object.keys(scrollHandles).filter(
              (key) => Number(key) < preloadMax,
            )
            handleKeys.forEach((key) => {
              const handle = scrollHandles[key]
              delete scrollHandles[key]
              handle()
            })
          }

          window.addEventListener(scrollEventName, preloadHander)
          const throttleEvent = function (eventName, customName, obj) {
            obj = obj || window
            const fn = function (e) {
              rafHandles[customName] = () => {
                obj.dispatchEvent(new CustomEvent(customName))
              }
            }
            obj.addEventListener(eventName, fn, !1)
          }

          const rafHandles = {}
          const raf = function () {
            window.requestAnimationFrame(() => {
              Object.keys(rafHandles).forEach((key) => {
                const fn = rafHandles[key]
                fn()
                delete rafHandles[key]
              })
              raf()
            })
          }
          raf()

          throttleEvent('resize', resizeEventName)
          throttleEvent('scroll', scrollEventName)
          window.dispatchEvent(new Event('window:scroll'))
        },
        addResizeHandle(cb) {
          resizeHandles.push(cb)
          return () => {
            const r = []
            resizeHandles.forEach((item) => {
              if (cb != item) r.push(item)
            })
            resizeHandles = r
          }
        },
        clearResizeHandle() {
          resizeHandles = []
        },

        addScrollHandle(triggerHeight, cb) {
          scrollHandles[triggerHeight] = cb
          return () => {
            delete scrollHandles[triggerHeight]
          }
        },
        clearScrollHandle() {
          scrollHandles = {}
        },
        get on() {
          return eventBus.$on.bind(eventBus)
        },
        get emit() {
          return eventBus.$emit.bind(eventBus)
        },
        get off() {
          return eventBus.$off.bind(eventBus)
        },
      }
    },
  })
  return vue
}

