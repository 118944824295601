<template>
  <section class="voice_helper_21">
    <div class="content_box">
      <div class="content_font">
        <h2>多轮对话上下文继承</h2>
        <p>对话周期内基于前文对话，继续保留对话时继承到的主语，无需多费口舌；<br />不同座位乘客无需重复发出指令，只需一句“我也要”，即可将设定同步</p>
      </div>
      
      <div class="car_bg_21" bg-url="@/auto/assets/image/21/bg.png" v-bg></div>

      <div class="helper_box_21">
        <div class="air_21" bg-url="@/auto/assets/image/21/air.png" v-bg></div>
        <div class="need_21" bg-url="@/auto/assets/image/21/need.png" v-bg></div>
      </div>

      <div class="some_order_21">
        <div class="order_content_21">
          <div class="font_box">
            <h5>多意图并行指令</h5>
            <p>一句话可以包括多个指令，再多要求也能有条不紊地执行</p>
          </div>

          <m-video class="video_order" src="@/auto/assets/video/21/parallel.mp4" poster="@/auto/assets/video/21/parallel_poster.png" width="792px" height="720px" loop muted ref="video_order"></m-video>
        </div>
        <div class="invalid_order_21">
          <h2>
            <span class="del_line_21"></span>
            无效对话<br />智能过滤
          </h2>
          <p>其它音区的乘客可随意聊天，想说<br />就说，帮你自动过滤掉无效语句</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'SecenHelper',
  
  methods: {
    fontLine() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.del_line_21',
          start: 'center center',
          end: 'center center',
          scrub: 0.3,
          // markers: true,
          onEnter:() => {
            this.$gsap.to('.del_line_21', { backgroundPositionX: '0%', duration: 1 })
          }
        }
      })
    },

    moveCar() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.car_bg_21',
          start: 'top center',
          end: '300px center',
          scrub: 0.1,
          // markers: true,
        }
      })

      tl.to('.car_bg_21', { top: 859, width: 1200, height: 522, borderBottomLeftRadius: 30, borderBottomRightRadius: 30 })
    },

    fontMove() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.helper_box_21',
          start: '100px center',
          end: '300px center',
          scrub: 0.1,
          // markers: true,
        }
      })

      tl.to('.air_21', { left: 180 })
      tl.to('.need_21', { left: 882 })
    },
    
    nextVisible() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.some_order_21',
          start: '0px bottom',
          end: '300px bottom',
          scrub: 0.8,
          // markers: true,
        }
      })

      tl.addLabel('font')
      tl.from('.order_content_21', { y: 300 }, 'font')
      tl.from('.invalid_order_21', { y: 700 }, 'font')
    },

    videoRunner() {
      const video = this.$refs.video_order

      video && videoPlayByVisibleArea(video)
    },

    onLaunch() {
      this.fontLine()
      this.moveCar()
      this.fontMove()

      this.nextVisible()

      this.videoRunner()
    }
  }
}
</script>

<style lang="less" scoped>
.voice_helper_21 {
  height: 2200px;
  width: 100%;
  background-color: #17181C;
  position: relative;
  overflow: hidden;

  .content_box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .content_font {
    position: absolute;
    top: 200px;
    width: 100%;
    text-align: center;

    h2 {
      font-weight: 700;
      font-size: 80px;
      line-height: 113px;
      text-align: center;
      margin: 0;

      background: linear-gradient(91.68deg, #00EE9C 0%, #22FDE2 48.96%, #05C3FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      margin: 20px 0 0 0;
    }
  }

  .car_bg_21 {
    position: absolute;
    top: 353px;
    left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    height: 900px;
    background-size: 100%;
    background-position: top center;
    z-index: 1;
  }

  .helper_box_21 {
    height: 780px;
    width: 1200px;
    margin: 600px auto 0 auto;
    background-color: #000;
    border-radius: 30px;
    overflow: hidden;
    position: relative;

    .air_21 {
      width: 300px;
      height: 258px;
      position: absolute;
      top: 56px;
      left: 450px;
      background-size: 100%;
    }

    .need_21 {
      position: absolute;
      top: 56px;
      left: 1200px;
      width: 138px;
      height: 119px;
      background-size: 100%;
    }
  }

  .some_order_21 {
    height: 720px;
    width: 1200px;
    display: flex;
    margin: 24px auto 0 auto;

    .order_content_21 {
      border-radius: 30px;
      background-color: #000;
      width: 792px;
      height: 100%;
      position: relative;
      overflow: hidden;
      
      .font_box {
        position: absolute;
        top: 44px;
        left: 44px;

        h5 {
          font-weight: 600;
          font-size: 48px;
          line-height: 68px;
          color: #FFFFFF;
          margin: 0;
        }
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: rgba(255, 255, 255, 0.6);
          margin: 10px 0 0 0;
        }
      }
    }

    .invalid_order_21 {
      flex: 1;
      background: linear-gradient(339.4deg, #A57DCD 0%, #514BC1 100%);
      border-radius: 24px;
      margin-left: 24px;
      
      h2 {
        font-weight: 700;
        font-size: 56px;
        line-height: 79px;
        color: #FFFFFF;
        margin: 245px 0 0 50px;
        display: inline-block;
        position: relative;
        

        .del_line_21 {
          position: absolute;
          top: 36px;
          left: 0;
          width: 224px;
          height: 8px;
          background: linear-gradient(90deg, #D1FFEC 0%, #1C3DF9 50%, transparent 50%, transparent 100%);
          background-size: 448px 8px;
          background-position: right top;
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.6);
        margin: 16px 0 0 50px;
      }
    }
  }

}
</style>
