/*
 @author: M.Wang
 Created by preference on 2023/01/10
*/

import Vue from 'vue'
import img from './components/img'
import Emitter from './mixins/emitter'
import gsapPlugin from './plugins/gsap'
import eventPlugin from './plugins/event'
import video from './components/video'
import bgDirective from './directives/bg'

Vue.config.productionTip = false

window.__preload__ = {}

Vue.use(gsapPlugin)
Vue.use(eventPlugin)

Vue.mixin(Emitter)

Vue.directive('bg', bgDirective)

Vue.component('m-img', img)
Vue.component('m-video', video)
