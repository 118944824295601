<template>
  <section class="front_cover_1">
    <div class="content_box">
      <div class="cover1_box">
        <m-video key="1" v-if="video1Visible" class="video_1" src="@/auto/assets/video/1/1.mp4" width="1920px" height="1080px"
          @ended="videoEnded($event)" muted autoplay></m-video>
        <m-video key="2" class="video_1" src="@/auto/assets/video/1/2.mp4" width="1920px" height="1080px"
          muted autoplay loop ref="video"></m-video>

        <div class="cover_box_parent_1" bg-url="@/auto/assets/image/1/bg.png" v-bg>
          <div class="cover_box_1" bg-url="@/auto/assets/image/1/bg.png" v-bg>       
            <canvas ref="canvasEl" class="cover_item_1" width="590" height="369"></canvas>
          </div>
        </div>
      </div>

      <div class="logo_font_box">
        <h1 class="logo_1" bg-url="@/auto/assets/image/1/logo.svg" v-bg></h1>
        <div class="see_more_1" @click="seeMoreHandle">
          <span>观看宣传片</span>
          <span class="play_icon" bg-url="@/auto/assets/image/1/play.svg" v-bg></span>
        </div>
      </div>
      
      <div class="more_desktop_1">
        <div class="desktop_box">
          <h3 class="title_1">多形态桌面</h3>

          <div class="desktop_box_1">
            <div class="desktop desktop1_1" bg-url="@/auto/assets/image/1/desktop1.jpg" v-bg>
              <div class="font_box_1 desktop_font1">
                <h6>壁纸桌面</h6>
                <p>使用自己的照片轻松 DIY 桌面，个性又独特</p>
              </div>
            </div>
            <div class="desktop desktop2_1" bg-url="@/auto/assets/image/1/desktop2.jpg" v-bg>
              <div class="font_box_1 desktop_font2">
                <h6>实况桌面</h6>
                <p>实时映射物理世界的时光、天气、车况</p>
              </div>
            </div>
            <div class="desktop desktop3_1" bg-url="@/auto/assets/image/1/desktop3.jpg" v-bg>
              <div class="font_box_1 desktop_font3">
                <h6>地图桌面</h6>
                <p>一键出行，更实用更高效</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition>
      <div class="video_mask" v-if="moreVisible">
        <div class="close_btn">
          <span class="btn btn1" @click="closeMask" bg-url="@/auto/assets/image/1/default.png" v-bg></span>
          <span class="btn btn2" @click="closeMask" bg-url="@/auto/assets/image/1/hover.png" v-bg></span>
        </div>

        <m-video class="mask_video" poster="@/auto/assets/video/1/brandvideo.jpg" src="@/auto/assets/video/1/0327brandvideo.mp4" width="70%" controls ref="mask_video"></m-video>
      </div>
    </transition>
  </section>
</template>

<script>
import FrameAnimation from '@/assets/vue/plugins/frame-animation'
import preloadImage from '@/assets/vue/plugins/preload-image'
import json from '../../../public/frame/1-imgs/index.json'

export default {
  name: 'FrontCover',

  data() {
    return {
      preload_: true, 
      
      video1Visible: true,
      moreVisible: false,
    }
  },
  
  methods: {
    videoEnded() {
      this.video1Visible = false
      
      const video = this.$refs.video
      video.play()
    },

    closeMask() {
      const video = this.$refs.mask_video
      video.pause()

      this.moreVisible = false
      document.body.style = 'overflow: inhert;'
    },
    
    seeMoreHandle() {
      this.moreVisible = true
      document.body.style = 'overflow: hidden;'
    },

    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.front_cover_1',
          end: '+=3500',
          pin: true,
        }
      })
    },

    switchImgVideo() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '350px top',
          end: '500px top',
          scrub: 0.1,
        }
      })

      tl.to('.video_1', { opacity: 0 })
      tl.to('.cover_box_parent_1', { opacity: 1 })
    },

    startCarAnimation() {
      const canvasEl = this.$refs.canvasEl

      setTimeout(() => { // 延迟素材加载，提供默认素材的优先级
        preloadImage(json, {
          baseUrl: 'frame/1-imgs/',
        }).then((imageList) => {
          this.fa = new FrameAnimation({
            canvas: canvasEl,
            initialFrame: 0,
            speedThreshold: 40,
            imageList: imageList,
          })
        })
      })

      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '500px top',
          end: '1000px top',
          scrub: 0.1,
          // markers: true,
          onUpdate: (e) => {
            this.fa && this.fa.play(e.progress)
          }
        }
      })

      tl.from('.cover_box_parent_1', { scale: 3.5, y: -800 })
    },

    startFont1Animation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '900px top',
          end: '1100px top',
          scrub: 0.1
        }
      })

      tl.from('.logo_1', { y: 150, opacity: 0 })
    },
    startFont2Animation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '950px top',
          end: '1100px top',
          scrub: 0.1
        }
      })

      tl.from('.see_more_1', { y: 150, duration: 1, opacity: 0 })
    },
    hiddenFont() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '2000px top',
          end: '2100px top',
          scrub: 0.1
        }
      })

      tl.addLabel('hidden_font')
      tl.to('.logo_1', { opacity: 0 }, 'hidden_font')
      tl.to('.see_more_1', { opacity: 0 }, 'hidden_font')
    },

    switchDesktop() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '2000px top',
          end: '2500px top',
          scrub: 0.1,
          // markers: true
        }
      })

      tl.to('.cover_box_1', { scale: 1.4, y: -216 })
      tl.to('.more_desktop_1', { opacity: 1 })
    },


    showDesktop() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          start: '2500px top',
          end: '3000px top',
          scrub: 0.1
        }
      })


      tl.addLabel('show_desktop')
      tl.to('.desktop1_1', { x: -434 }, 'show_desktop')
      tl.to('.desktop3_1', { x: 434 }, 'show_desktop')

      tl.addLabel('show_font')
      tl.to('.title_1', { opacity: 1 }, 'show_font')
      tl.to('.font_box_1', { opacity: 1 }, 'show_font')
    },

    onLaunch() {
      this.pagePin()
      this.switchImgVideo()
      this.startCarAnimation()
      this.startFont1Animation()
      this.startFont2Animation()

      this.switchDesktop()
      this.hiddenFont()
      this.showDesktop()
    }
  },
}
</script>

<style lang="less" scoped>
.front_cover_1 {
  height: 1080px;
  width: 100%;
  position: relative;
  background-color: #17181C;
  
  .content_box {
    width: 100%;
    height: 100vh;
    max-height: 1080px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .cover1_box {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #000;
    overflow: hidden;
  }

  .video_1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    &:first-child {
      z-index: 1;
    }
  }

  .cover_box_parent_1 {
    width: 100%;
    height: 1080px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 2560px 1080px;
    background-repeat: no-repeat;
    overflow: hidden;
    
    opacity: 0;
  }
  .cover_box_1 {
    width: 100%;
    height: 1080px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-size: 2560px 1080px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .cover_item_1 {
    position: absolute;
    top: 574px;
    left: 50%;
    transform: translateX(-50%);
    width: 590px;
    height: 369px;
  }

  .logo_font_box {
    .logo_1 {
      position: absolute;
      z-index: 2;
      top: calc(50% - 260px);
      left: 50%;
      transform: translateX(-50%);
      width: 554px;
      height: 130px;
      background-size: 100% 100%;
    }
    .see_more_1 {
      position: absolute;
      z-index: 2;
      top: calc(50% - 103px);
      left: 50%;
      transform: translateX(-50%);
      width: 130px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #1368FB;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .play_icon {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }

  .more_desktop_1 {
    position: absolute;
    z-index: 1;
    top: 50vh;
    transform: translateY(-50%);
    width: 100%;
    height: 100vh;
    min-height: 1080px;
    background-color: #17181C;
    opacity: 0;
    overflow: hidden;

    .desktop_box {
      width: 100%;
      height: 100%;
    }

    .title_1 {
      margin: 212px 0 72px 0;
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #FFFFFF;
      opacity: 0;
    }

    .desktop_box_1 {
      height: 520px;
      position: relative;
    }
    .desktop {
      position: absolute;
      background-size: 100% 100%;
      background-clip: content-box;
      border: 10px solid #000;
      border-radius: 12px;

      left: 50%;
      transform: translateX(-50%);
    }
    .desktop1_1 {
      top: 60px;
      width: 640px;
      height: 400px;
    }
    .desktop2_1 {
      top: 0;
      width: 825px;
      height: 516px;
      z-index: 1;
    }
    .desktop3_1 {
      top: 60px;
      width: 640px;
      height: 400px;
    }

    .desktop_font1 {
      bottom: -170px;
      left: 100px;
    }
    .desktop_font2 {
      bottom: -170px;
      left: 50%;
      transform: translateX(-50%);
    }
    .desktop_font3 {
      bottom: -170px;
      right: 100px;
    }

    .font_box_1 {
      position: absolute;
      opacity: 0;

      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        margin: 0;
      }

      p {
        width: 354px;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: rgba(255, 255, 255, 0.4);
        margin: 12px 0 0 0;
      }
    }
  }

  .video_mask {
    position: fixed;
    width: 100vw;
    min-width: 1200px;
    height: 100vh;
    z-index: 100;
    background-color: #000;

    .close_btn {
      position: absolute;
      top: 82px;
      right: 82px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      
      &:hover {
        .btn1 {
          display: none;
        }

        .btn2 {
          display: inline-block;
        }
      }
      
      .btn {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: 100%;
      }

      .btn2 {
        display: none;
      }
    }
  }

  .mask_video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
