/*
 @author: M.Wang
 Created by preference on 2023/01/10
*/
export const sectionMixin = (idx) => ({
  data() {
    return {
      idx,
      preload_: !1, // 是否直接加载资源
    }
  },
  mounted() {
    this.bindScrollEvent_()
    this.$event.addResizeHandle(() => {
      if (!this.preload_) {
        this.bindScrollEvent_()
      }
    })
    if (typeof this.onLaunch === 'function') {
      this.onLaunch()
    }
  },
  methods: {
    onPreload_() {
      if (!window.__preload__) {
        window.__preload__ = {}
      }
      if (this.$el) {
        // 加载背景图片
        const bgEls = Array.prototype.slice.call(
          this.$el.querySelectorAll('[bg-url]'),
        )

        bgEls.forEach((el) => {
          let bgUrl = el.getAttribute('bg-url')
          el.style.backgroundImage = `url(${bgUrl})`
          window.__preload__[bgUrl] = !0
        })
      }
    },

    triggerChildPreload__() {
      const fn = (childs) => {
        childs.forEach((child) => {
          if (typeof child.onPreload === 'function') {
            child.onPreload()
          }
          if (child.$children.length > 0) fn(child.$children)
        })
      }

      this.$children.length > 0 && fn(this.$children)
    },
    bindScrollEvent_() {
      if (this.preload_) {
        this.onPreload_()
        return
      }
      const rect = this.$el.getBoundingClientRect()
      this.$event.addScrollHandle(rect.top.toFixed(), () => {
        this.preload_ = true
        this.onPreload_()
        this.triggerChildPreload__()
        if (typeof this.onPreload === 'function') {
          this.onPreload()
        }
      })
    },
  },
})
