<template>
  <section class="self_helper_24">
    <div class="content_box">
      <div class="font_box_24">自定义情感化<br />有温度的语音</div>

      <div class="item_content">
        <div class="item">
          <div class="font_box">
            <h5>自定义唤醒词</h5>
            <p>“私人定制”小溪的独家姓名，每次唤醒更有趣</p>
          </div>
          <m-img src="@/auto/assets/image/24/hey_aicy.jpg" width="588px" height="800px"></m-img>
        </div>

        <div class="item">
          <div class="font_box">
            <h5>丰富的自选音色</h5>
            <p>10+ 音色任你选，搭配不同的性格和语气，让小溪用你最喜欢的<br />声音说话</p>
          </div>
          <m-img src="@/auto/assets/image/24/voice_more.jpg" width="588px" height="800px"></m-img>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: 'SelfHelper',

  data() {
    return {
    }
  },
  
  methods: {
    onLaunch() {
    }
  }
}
</script>

<style lang="less" scoped>
.self_helper_24 {
  width: 100%;
  height: 1200px;
  background-color: #17181C;
  overflow: hidden;
  position: relative;

  .content_box {
    width: 1200px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 140px;
    transform: translateX(-50%);
  }
  .font_box_24 {
    font-weight: 600;
    font-size: 68px;
    line-height: 96px;
    width: 408px;
    text-align: center;
    margin: 0 auto;

    background: linear-gradient(91.68deg, #00EE9C 0%, #22FDE2 48.96%, #05C3FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

  }

  .item_content {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    background-color: #000;
    height: 800px;
    width: 588px;
    margin-top: 60px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    &:nth-child(2) {
      margin-left: 24px;
    }
  }

  .font_box {
    position: absolute;
    top: 44px;
    left: 44px;

    h5 {
      font-weight: 600;
      font-size: 48px;
      line-height: 68px;
      color: #FFFFFF;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin: 10px 0 0 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
