<template>
  <section class="continue_49">
    <div class="content_box">
      <h3>精彩继续</h3>    

      <ul class="func_box">
        <li class="func_item">
          <div class="icon" bg-url="@/auto/assets/image/49/1.png" v-bg></div>
          <div class="font_box">
            <h5>多用户空间</h5>
            <p>账号匹配独立用户空间，用车习惯、音视频、游戏等账号数据随之切换，隔离不同用户的隐私和资产</p>
          </div>
        </li>
        <li class="func_item">
          <div class="icon" bg-url="@/auto/assets/image/49/2.png" v-bg></div>
          <div class="font_box">
            <h5>安全隐私保护</h5>
            <p>敏感权限使用时在状态栏实时提醒，副驾有人时自动隐藏电话、地图、相册等内容</p>
          </div>
        </li>
        <li class="func_item">
          <div class="icon" bg-url="@/auto/assets/image/49/4.png" v-bg></div>
          <div class="font_box">
            <h5>图库</h5>
            <p>根据地理位置记录相片足迹，支持滤镜、贴纸、批注、剪裁等功能，满足个性化编辑体验</p>
          </div>
        </li>
        <li class="func_item">
          <div class="icon" bg-url="@/auto/assets/image/49/5.png" v-bg></div>
          <div class="font_box">
            <h5>系统音效</h5>
            <p>多类别音量分别控制，丰富的媒体音效定制能<br />力，提示音也可按需定义</p>
          </div>
        </li>
        <li class="func_item">
          <div class="icon" bg-url="@/auto/assets/image/49/6.png" v-bg></div>
          <div class="font_box">
            <h5>故障可视化</h5>
            <p>将晦涩难懂的仪表标识与车辆故障，进行可视化的提示与引导，给用户提供全方位的帮助</p>
          </div>
        </li>
      </ul>

      <p class="extra">*部分功能持续开发中，以实际使用情况为准。</p>
    </div>
  </section>
</template>

<script>


export default {
  name: 'Continue',
}
</script>

<style lang="less" scoped>
.continue_49 {
  height: 1080px;
  width: 100%;
  position: relative;
  background-color: #F7F8FA;
  overflow: hidden;
  
  .content_box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    height: 100%;

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #000000;
      margin: 90px 0 60px 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .func_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .func_item {
    background-color: #fff;
    border-radius: 30px;
    width: 588px;
    height: 220px;
    padding: 60px 41px 31px 40px;
    list-style: none;
    box-sizing: border-box;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  .icon {
    width: 90px;
    height: 90px;
    background-size: 100%;
  }
  
  .font_box {
    padding-left: 30px;
    flex: 1;

    h5 {
      font-weight: 600;
      font-size: 28px;
      line-height: 39px;
      color: #000;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.5);
      margin: 6px 0 0 0;
    }
  }

  .extra {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.2);
  }
}
</style>
