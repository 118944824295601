import Vue from 'vue'
const eventBus = new Vue()

export default class FrameAnimation {
  constructor(options) {
    this._canvas = options.canvas
    this._imageList = options.imageList
    this._context = this._canvas.getContext('2d')
    this._imageLength = this._imageList.length
    this._currentIndex = options.initialFrame || 0 // [0,this._imageLength]
    this._targetIndex = this._imageLength // [0,this._imageLength]
    this._isRunning = false
    this._rafId = null
    this._speedThreshold = options.speedThreshold
    // this.speed = options.speed || 1 // 速率

    this._draw(this._imageList[this._currentIndex])
  }
  get on() {
    return eventBus.$on.bind(eventBus)
  }
  get off() {
    return eventBus.$off.bind(eventBus)
  }
  get progress() {
    return this._frameNum2progress()
  }
  _progress2FrameNum(num) {
    return Math.round(num * this._imageLength)
  }
  _frameNum2progress() {
    return this._currentIndex / this._imageLength
  }
  _draw(image) {
    this._context.clearRect(0, 0, this._canvas.width, this._canvas.height)
    this._context.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      this._canvas.width,
      this._canvas.height,
    )
  }
  _run() {
    const { _currentIndex, _targetIndex, _imageList, speed } = this
    let reverse = _targetIndex < _currentIndex
    let nextFrame = reverse
      ? _imageList.slice(_targetIndex, _currentIndex).pop()
      : _imageList.slice(_currentIndex, _targetIndex).shift()
    if (nextFrame) {
      if (!this._isRunning) {
        eventBus.$emit.apply(eventBus, ['animationstart'])
      }
      this._isRunning = true
      this._draw(nextFrame)
      const spd = this._speedThreshold
        ? Math.ceil(
            Math.abs(_targetIndex - _currentIndex) / this._speedThreshold,
          )
        : 1
      this._currentIndex = reverse
        ? Math.max(_currentIndex - spd, _targetIndex)
        : Math.min(_currentIndex + spd, _targetIndex)

      //console.log(this._currentIndex, _targetIndex,'disx:',_targetIndex - this._currentIndex)

      this._rafId = window.requestAnimationFrame(this._run.bind(this))
    } else {
      if (this._isRunning) {
        eventBus.$emit.apply(eventBus, ['animationend'])
      }
      this._isRunning = false
    }
  }
  _progress(startNum, endNum) {
    if (typeof startNum !== 'number') {
      return
    }
    if (endNum === undefined) {
      this._targetIndex = this._progress2FrameNum(startNum)
    } else {
      this._currentIndex = this._progress2FrameNum(startNum)
      this._targetIndex = this._progress2FrameNum(endNum)
    }
  }
  pause() {
    if (this._rafId) {
      this._isRunning = false
      window.cancelAnimationFrame(this._rafId)
    }
  }
  /**
   * @description: 播放帧动画
   * @param {*} startNum [0,1] 开始进度 | 结束进度
   * @param {*} endNum  [0,1] 结束进度
   * @return {*}
   */
  play(startNum, endNum) {
    this._progress(startNum, endNum)
    if (!this._isRunning) {
      this._run()
    }
  }
  /**
   * @description: 跳转到
   * @param {*} number 【0,1】
   * @return {*}
   */
  seek(num) {
    const newIndex = this._progress2FrameNum(num)
    if (newIndex !== this._currentIndex) {
      this._currentIndex = newIndex
      const nextFrame = this._imageList[this._currentIndex]
      if (nextFrame) {
        this._draw(nextFrame)
      }
    }
  }
}
