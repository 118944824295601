/**
 * @description:
 * @param {*} fileList
 * @return {*}
 */
export default function (config, { baseUrl } = { baseUrl: '' }) {
  const fileList = window._webp_ ? config.webp : config.common
  baseUrl = location.protocol + process.env.VUE_APP_FRAME_CDN_URL + baseUrl
  return Promise.all(fileList.map((item) => getBlob(item, baseUrl))).then(
    (res) => res.flat(),
  )
}



const getBlob = (fileList, baseUrl) => {
  if (!Array.isArray(fileList)) {
    fileList = [fileList]
  }
  return Promise.all(fileList.map((item) => file2blob(item, baseUrl))).then(
    (res) => res.flat(),
  )
}

const loadImage = (url) => {
  return new Promise((resolve) => {
    const image = new Image()
    const URL = window.URL || window.webkitURL

    image.onload = () => {
      URL.revokeObjectURL(url)
      resolve(image)
    }
    image.onerror = (err) => {
      URL.revokeObjectURL(url)
    }
    image.src = url
  })
}



function file2blob(item, baseUrl) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    const url = baseUrl + `${item.imageType}/${item.fileName}`
    xhr.withCredentials = false
    xhr.open('GET', url)
    xhr.responseType = 'arraybuffer'
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) {
          reject(`${url} not found`)
        } else {
          const URL = window.URL || window.webkitURL
          const image = []
          const concatBuffer = xhr.response
          item.fileBytes.reduce((prev, val) => {
            const end = prev + val
            const buf = concatBuffer.slice(prev, end)
            image.push(URL.createObjectURL(new Blob([buf], { type: item.imageType })))
            return end
          }, 0)
          Promise.all(image.map((url) => loadImage(url))).then((imageList) =>
            resolve(imageList),
          )
        }
      }
    }
    xhr.send()
  })
}
