<template>
  <section class="link_32">
    <div class="link_box_32">
      <div class="phone_model music_phone_32" bg-url="@/auto/assets/image/32/music.png" v-bg>
        <div class="phone_music" bg-url="@/auto/assets/image/32/phone.png" v-bg></div>

        <div class="music_icon_32" bg-url="@/auto/assets/image/32/music_icon.png" v-bg></div>
      </div>
      <div class="phone_model map_phone_32" bg-url="@/auto/assets/image/32/phone_map.jpg" v-bg>
        <div class="phone_music" bg-url="@/auto/assets/image/32/phone.png" v-bg></div>
      </div>

      <div class="font_box">
        <span class="font font_32 font_left1_32">无</span>
        <span class="font font_32 font_left2_32">缝</span>
        <span class="font font_32 font_right1_32">接</span>
        <span class="font font_32 font_right2_32">力</span>
      </div>

      <m-video class="car_desktop car_music_32" src="@/auto/assets/video/32/music.mp4" poster="@/auto/assets/video/32/music_poster.jpg" width="800px" height="500px" muted ref="car_music" loop></m-video>
      <div class="car_desktop car_map_32" bg-url="@/auto/assets/image/32/map.jpg" v-bg></div>
    
      <p class="content_desc desc1_32">上车时，手机端播放的 QQ 音乐自动隔空流转至车上播放，听歌无缝接力</p>
      <p class="content_desc desc2_32">上车时，高德导航自动流转，即刻出发；<br />下车后，路线无缝流转回手机，步行路线不中断</p>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Link',
  
  methods: {
    linkPin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.link_32',
          start: 'top top',
          end: '+=5500 top',
          pin: true,
          // markers: true
        }
      })
    },

    runAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.music_phone_32',
          start: 'top center',
          end: '4000px center',
          scrub: 0.1,
          // markers: true
        }
      })

      tl.addLabel('font')
      tl.to('.font_32', { top: 500 }, 'font')
      tl.to('.music_phone_32', { top: 210 }, 'font')

      tl.addLabel('not_font')
      tl.to('.font_left1_32', { left: -1000 }, 'not_font')
      tl.to('.font_left2_32', { left: -100 }, 'not_font')
      tl.to('.font_right1_32', { left: 2020 }, 'not_font')
      tl.to('.font_right2_32', { left: 3020 }, 'not_font')
      tl.to('.music_phone_32', { top: 259, left: 400, width: 252, height: 538 }, 'not_font')
      tl.to('.music_icon_32', { x: 840, y: 46, opacity: 0 }, 'not_font')
      
      tl.addLabel('show_desc')
      tl.to('.car_music_32', { opacity: 1 }, 'show_desc')
      tl.to('.desc1_32', { opacity: 1 }, 'show_desc')
      
      tl.to('.link_box_32', { duration: 1 })

      tl.addLabel('music')
      tl.to('.music_phone_32', { top: 183, opacity: 0 }, 'music')
      tl.to('.car_music_32', { top: 202, opacity: 0 }, 'music')
      tl.to('.desc1_32', { opacity: 0 }, 'music')
      
      tl.addLabel('map')
      tl.to('.map_phone_32', { top: 259, opacity: 1 }, 'map')
      tl.to('.car_map_32', { top: 278, opacity: 1 }, 'map')
      tl.to('.desc2_32', { opacity: 1, delay: 0.5 }, 'map')
    },

    videoRunner() {
      const video = this.$refs.car_music

      video && videoPlayByVisibleArea(video)
    },

    onLaunch() {
      this.linkPin()
      this.runAnimation()

      this.videoRunner()
    }
  }
}
</script>

<style lang="less" scoped>
.link_32 {
  background-color: #17181C;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.link_box_32 {
  height: 1056px;
  width: 1920px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .phone_model {
    position: absolute;
    top: 188px;
    left: 795px;
    width: 330px;
    height: 704px;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;

    .phone_music {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }
  }
  .music_phone_32 {
    top: 288px;
  }
  .music_icon_32 {
    position: absolute;
    top: 100px;
    transform: translateX(-50%);
    left: 50%;
    width: 254px;
    height: 254px;
    background-size: 100% 100%;
  }

  .map_phone_32 {
    top: 383px;
    left: 400px;
    width: 252px;
    height: 538px;
    opacity: 0;
    background-size: calc(100% - 20px) calc(100% - 20px);
  }

  .font_box {
    position: relative;
    width: 100%;
    height: 100%;

    .font {
      font-weight: 600;
      font-size: 80px;
      line-height: 113px;
      color: #FFFFFF;
      position: absolute;
      top: 430px;

      &:nth-child(1) {
        left: 400px;
      }
      &:nth-child(2) {
        left: 616px;
      }
      &:nth-child(3) {
        left: 1224px;
      }
      &:nth-child(4) {
        left: 1440px;
      }
    }
  }

  .car_desktop {
    position: absolute;
    top: 278px;
    right: 400px;
    width: 800px;
    height: 500px;
    border: 10px solid #000000;
    border-radius: 12px;
    opacity: 0;
  }
  .car_map_32 {
    top: 402px;
    background-size: 100%;
  }

  .content_desc {
    position: absolute;
    bottom: 134px;
    left: 710px;
    width: 442px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
    opacity: 0;

    &+.content_desc {
      opacity: 0;
    }
  }
}
</style>
