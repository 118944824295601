/*
 @author: M.Wang
 Created by preference on 2023/01/09
*/
const imgRe = /.*\.(jpe?g|png)$/i
export default {
  bind: function (el) {
    let src = el.getAttribute('bg-url')
    if (!src) return

    if (src.includes('data:image/')) {
      el.style.backgroundImage = `url(${src})`
      el.removeAttribute('bg-url')
      return
    }


    // 代码无用
    if (
      process.env.NODE_ENV === 'production' &&
      imgRe.test(src) &&
      window._webp_
    ) {
      src = src.replace(/(?:\.\w+)(\?|$)/, '.webp$1')
      el.setAttribute('bg-url', src)
    }

    if (window.__preload__ && window.__preload__[src]) {
      el.style.backgroundImage = `url(${src})`
      el.removeAttribute('bg-url')
    }
  },
}
