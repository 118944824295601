<template>
  <section class="interaction_6">
    <div class="content_box">
      <div class="font_box">
        <div class="font_item font1_6">
          <h2>突破边界，延展想象</h2>
          <p>多屏幕内容联动，甚至打破了屏幕边界，<br />去影响氛围灯的颜色变化，合情合理且又无比沉浸</p>
        </div>

        <div class="font_item font2_6">
          <h2>自然无界， 打破沉寂</h2>
          <p>休憩空间下的萤火虫，当你打开车门时也会受到惊扰而飞出屏幕、飞到氛围灯里，<br />有趣的互动打破了系统和座舱硬件的边界，静谧又治愈</p>
        </div>
      </div>

      <m-video class="video" src="@/auto/assets/video/6/1.mp4" poster="@/auto/assets/video/6/1_poster.jpg" @timeupdate="timeupdate($event)" muted loop ref="video"></m-video>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Interaction',

  data() {
    return {
      videoEnd: false
    }
  },

  methods: {
    timeupdate(e) {
      const currentTime = e.target.currentTime
      if(currentTime >= 3) {
        if(!this.videoEnd) {
          this.videoEnd = true
          this.fontChange()
        }
        return
      }

      if(currentTime >= 0) {
        if(this.videoEnd) {
          this.videoEnd = false
          this.fontChange1()
        }
      }
    },

    fontChange() {
      this.$gsap.to('.font1_6', { rotateX: '90', top: -75, opacity: 0 })
      this.$gsap.to('.font2_6', {
        rotateX: '0', top: 0, opacity: 1,
        onComplete: () => {
          this.$gsap.to('.font1_6', { rotateX: '-90', top: 75, opacity: 0, duration: 0 })
        }
      })
    },

    fontChange1() {
      this.$gsap.to('.font2_6', { rotateX: '90', top: -75, opacity: 0 })
      this.$gsap.to('.font1_6', {
        rotateX: '0', top: 0, opacity: 1,
        onComplete: () => {
          this.$gsap.to('.font2_6', { rotateX: '-90', top: 75, opacity: 0, duration: 0 })
        }
      })
    },

    onLaunch() {
      const videoDom = this.$refs.video
      videoPlayByVisibleArea(videoDom)
    },
  }
}
</script>

<style lang="less" scoped>
.interaction_6 {
  min-height: 1080px;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #17181C;

  .content_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .font_box {
    width: 792px;
    height: 150px;
    position: absolute;
    top: 140px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    perspective: 500;
    -webkit-perspective: 500;
    -moz-perspective: 500;
    backface-visibility: hidden;

    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #FFFFFF;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      margin: 8px 0 0 0;
    }
    

    .font_item {
      position: absolute;
      transform-origin: center center -55px;
      width: 100%;
    }
    .font1_6 {
      top: 0;
    }
    .font2_6 {
      top: 75px;
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }

  .video {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate(-50%);
  }
}
</style>
