<template>
  <section class="visual_engine_9">
    <div class="engine_box">
      <div class="font_box_9">
        <h3>知意动效</h3>

        <h5>超强视效，<br />生动愉悦</h5>
        <p>充电时，能量从四周向电量柱聚集；燃油补电时，<br />能量从燃油柱往电量柱流转，生动而形象</p>
      </div>

      <m-video class="engine_video video1_9" src="@/auto/assets/video/9/charge.mp4" poster="@/auto/assets/video/9/charge_poster.png" width="800px" height="500px"
        muted loop ref="video1"></m-video>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'VisualEngine',
  
  methods: {
    onLaunch() {
      const video = this.$refs.video1

      video && videoPlayByVisibleArea(video)
    }
  }
}
</script>

<style lang="less" scoped>
.visual_engine_9 {
  background-color: #181a1f;
  position: relative;
  height: 900px;
  width: 100%;
  overflow: hidden;

  .engine_box {
    width: 1920px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .font_box_9 {
    position: absolute;
    width: 447px;
    top: 300px;
    left: 400px;

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 39px;
      width: 112px;
      margin: 0;

      background: linear-gradient(95.33deg, #29F2E6 0%, #1368FB 98.03%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    h5 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #FFFFFF;
      margin: 10px 0 8px 0;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .engine_video {
    position: absolute;
    left: 908px;
    top: 181px;
    width: 800px;
    height: 500px;
    box-shadow: 0px 30px 60px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 10px solid #000000;
  }
}
</style>
