<template>
  <section class="multiple_scene_22">
    <div class="content_box">
      <h3 class="font_box_22">多音区、全场景<br />语音说到做到</h3>

      <div class="content_item all_scene">
        <div class="font_box">
          <h2>海量应用场景<br />可见即可说</h2>
          <p>只需说出当前屏幕中的文字，车机即可识别并执行相应<br />命令，解放双手，一语直达</p>
        </div>
        <div class="all_scene_content" bg-url="@/auto/assets/image/22/visible_speak.jpg" v-bg></div>

        <p class="extra">*可见即可说覆盖的APP范围，以实际使用情况为准，后续将持续覆盖全场景应用</p>
      </div>

      <div class="content_item video_box_22">
        <div class="some_voice_22">
          <div class="font_box">
            <h5>高频场景免唤醒</h5>
            <p>支持免唤醒回桌面，不再需要说出“小溪小溪”，直接下达“打开导航、播控音乐、回桌<br />面”，响应更快速</p>
          </div>

          <m-video src="@/auto/assets/video/22/wakeup.mp4" poster="@/auto/assets/video/22/wakeup_poster.png" width="792px" height="720px" loop muted ref="scene_video"></m-video>
        </div>

        <div class="voice_online_22" bg-url="@/auto/assets/image/22/outline.jpg" v-bg>
          <h2>离线语音<br />眨眼间执行</h2>
          <p>在地下车库、高速、户外等无网或<br />弱网环境下也能正常对话</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'SecenHelper',

  methods: {
    nextVisible() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.video_box_22',
          start: '0px bottom',
          end: '300px bottom',
          scrub: 0.8,
          // markers: true,
        }
      })

      tl.addLabel('font')
      tl.from('.some_voice_22', { y: 300 }, 'font')
      tl.from('.voice_online_22', { y: 700 }, 'font')
    },

    videoRunner() {
      const video = this.$refs.scene_video

      video && videoPlayByVisibleArea(video)
    },

    onLaunch() {
      this.nextVisible()
      this.videoRunner()
    }
  }
}
</script>

<style lang="less" scoped>
.multiple_scene_22 {
  height: 1885px;
  width: 100%;
  background-color: #17181C;
  position: relative;
  overflow: hidden;

  .content_box {
    width: 1200px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .font_box_22 {
    margin: 160px auto 60px auto;
    font-weight: 600;
    width: 476px;
    font-size: 68px;
    line-height: 96px;
    text-align: center;

    background: linear-gradient(91.68deg, #00EE9C 0%, #22FDE2 48.96%, #05C3FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .content_item {
    margin-bottom: 24px;
  }

  .all_scene {
    height: 624px;
    border-radius: 30px;
    background-color: #000;
    display: flex;
    overflow: hidden;
    position: relative;

    .font_box {
      width: 612px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 140px;
      box-sizing: border-box;

      h2 {
        font-weight: 700;
        font-size: 56px;
        line-height: 79px;
        color: #FFFFFF;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.5);
        margin: 8px 0 0 0;
      }
    }

    .all_scene_content {
      flex: 1;
      background-size: 100%;
    }

    .extra {
      position: absolute;
      bottom: 20px;
      right: 32px;
      font-weight: 400;
      font-size: 13px;
      line-height: 28px;
      text-align: right;
      color: rgba(255, 255, 255, 0.2);
    }
  }

  .video_box_22 {
    height: 720px;
    display: flex;
    
    .some_voice_22 {
      position: relative;
      width: 792px;
      border-radius: 24px;
      background-color: #000;
      border-radius: 30px;
      overflow: hidden;

      .font_box {
        position: absolute;
        top: 44px;
        left: 44px;

        h5 {
          font-weight: 700;
          font-size: 48px;
          line-height: 68px;
          color: #FFFFFF;
          margin: 0;
        }
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: rgba(255, 255, 255, 0.6);
          margin: 10px 0 0 0;
        }
      }
    }

    .voice_online_22 {
      height: 100%;
      flex: 1;
      margin-left: 24px;
      border-radius: 24px;
      overflow: hidden;
      background-size: 100%;
    
      h2 {
        font-weight: 700;
        font-size: 56px;
        line-height: 79px;
        color: #FFFFFF;
        margin: 245px 0 0 50px;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.6);
        margin: 16px 0 0 50px;
      }
    }
  }
}
</style>
