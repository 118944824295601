/*
 @author: M.Wang
 Created by preference on 2023/01/10
*/

import { emptyImg } from './emptyImg'

const imgRe = /.*\.(jpe?g|png)$/i

export default {
  functional: true,
  props: {
    src: String,
  },
  render(h, ctx) {
    const $props = ctx.props
    let src = $props.src
    if (
      process.env.NODE_ENV === 'production' &&
      imgRe.test(src) &&
      window._webp_
    ) {
      const srcArr = $props.src.split('.')
      srcArr.pop()
      src = [...srcArr, 'webp'].join('.')
    }

    const getParent = (ctx) => {
      if (!ctx) return null
      let parent = ctx.parent || ctx.$parent
      if (parent && 'preload_' in parent) return parent
      else return getParent(parent)
    }

    parent = getParent(ctx)

    ctx.data.attrs.src = !parent ? src : parent.preload_ ? src : emptyImg
    return h('img', ctx.data)
  },
}
