/*
 @author: M.Wang
 Created by preference on 2023/01/09
*/

import { sectionMixin } from '@/assets/vue/mixins/section'
/**
 * 创建区块
 * @author M.Wang
 * @date 2023-01-09
 * @param {ComponentOptions<Vue>} options?:ComponentOptions<Vue>
 * @returns {any}
 */
let idx = 0
export function createSection(options) {
  const mixins = options.mixins || []
  mixins.push(sectionMixin(idx++))
  options.mixins = mixins
  return options
}

/**
 * 获取滚动条高
 * @author M.Wang
 * @date 2023-01-14
 * @returns {any}
 */
export function getScrollTop() {
  let r = 0
  if (document.documentElement && document.documentElement.scrollTop) {
    r = document.documentElement.scrollTop
  } else if (document.body) {
    r = document.body.scrollTop
  }
  return r
}

/**
 * 防抖函数
 * fn(函数， 时间ms=1000)
 */
export function debounce(fn, delay = 1000) {
  let timer

  return function () {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

/** 
 * 视频在可视窗口时播放视频
 * fn(video标签dom, 显示区域=0.5, needPause = 是否需要自动暂停功能，默认true)
 */
export function videoPlayByVisibleArea(dom, areaRate = 0.5, needPause = true) {
  const loop = dom.loop

  const callback = (e) => {

    if (e?.[0]?.intersectionRatio >= areaRate) {
      dom.play()
    } else {
      needPause && dom.pause()
    }
  }

  const io = new IntersectionObserver(callback, { delay: 0, threshold: areaRate })
  io.observe(dom)

  !loop && dom.addEventListener('ended', () => {
    io.disconnect()
  })
}

/** 
 * 视频在可视窗口时播放视频，h5兼容代码
 * fn(video标签dom, 显示区域=0.5, needPause = 是否需要自动暂停功能，默认true)
 */
export function videoPlayByVisibleAreaH5(dom, areaRate = 0.5, needPause = true) {
  const loop = dom.loop

  const callback = (e) => {

    if (e?.[0]?.intersectionRatio >= areaRate) {
      const func = () => {
        dom.play()
        document.removeEventListener('click', func)
      }

      dom.play()
      document.addEventListener('click', func)
    } else {
      needPause && dom.pause()
    }
  }

  const io = new IntersectionObserver(callback, { delay: 0, threshold: areaRate })
  io.observe(dom)

  !loop && dom.addEventListener('ended', () => {
    io.disconnect()
  })
}

/** 
 * dom在可视窗口时调用回调函数
 * fn(video标签dom, 显示区域=0.5, callback=() => {}, disconnect = true：结束时，是否注销observer)
 */
export function domVisibleArea(dom, areaRate = 0.5, callback = () => { }, disconnect = true) {
  const io = new IntersectionObserver(function (e, observer) {
    if (e?.[0]?.intersectionRatio >= areaRate) {
      callback()

      if (disconnect) {
        observer.disconnect()
      }
    }
  }, { delay: 0, threshold: areaRate })

  io.observe(dom)
}
