<template>
  <section class="entertainment_space_41">
    <div class="content_box_41">
      <h1 class="title_41">移动娱乐空间</h1>

      <div class="car_model_41">
        <div class="car_model_item car_model1_41" bg-url="@/auto/assets/image/41/car_model.png" v-bg></div>

        <div class="img_box_41">
          <m-img class="car_img img1_41" src="@/auto/assets/image/41/music.jpg" />
          <m-img class="car_img img2_41" src="@/auto/assets/image/41/k.jpg" />
          <m-img class="car_img img3_41" src="@/auto/assets/image/41/game.png" />
        </div>
        
        <div class="car_model_item car_model2_41" bg-url="@/auto/assets/image/41/model_k.png" v-bg></div>
        <div class="car_model_item car_model3_41" bg-url="@/auto/assets/image/41/model_game.png" v-bg></div>

        <div class="font_box_41">
          <ul class="music_41">
            <li><h5 class="other_color color1">千万曲库</h5><p>携手QQ音乐，海量无损优质曲库</p></li>
            <li><h5 class="other_color color2">懂你想听</h5><p>个性推荐，千人千面</p></li>
            <li><h5 class="other_color color3">声临其境</h5><p>沉浸听歌氛围，视听的极致碰撞</p></li>
            <li><h5 class="other_color color4">音乐接力</h5><p>上车音乐无缝衔接，丝滑流转</p></li>
          </ul>
          
          <ul class="station_41">
            <li><h5 class="other_color color5">2000 万</h5><p>全民 K 歌热门伴奏</p></li>
            <li><h5 class="other_color color6">10 种</h5><p>定制音效，魔兽/小黄人趣味变声</p></li>
            <li><h5 class="other_color color7">收藏歌单</h5><p>QQ音乐收藏歌单同步，唱你爱唱</p></li>
            <li><h5 class="other_color color8">弹幕+喝彩</h5><p>手机发弹幕、发喝彩，快乐加倍</p></li>
          </ul>
          
          <ul class="game_41">
            <li><h5 class="other_color color9">精选游戏</h5><p>战魂铭人、元气骑士多款游戏<br />持续更新</p></li>
            <li><h5 class="other_color color10">虚拟手柄</h5><p>手机充当虚拟手柄，一起嗨玩</p></li>
            <li><h5 class="other_color color11">沉浸体验</h5><p>氛围灯联动变换，对战更激烈</p></li>
          </ul>
        </div>
        
        <div class="handle_img_41" bg-url="@/auto/assets/image/41/handle.png" v-bg></div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: 'EntertainmentSpace',
  
  methods: {
    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.entertainment_space_41',
          end: '+=3500',
          pin: true
        }
      })
    },
    runAnimation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.content_box_41',
          start: '1000px center',
          end: '1500px center',
          scrub: 0.1,
          // markers: true
        }
      })

      tl.addLabel('move')
      tl.to('.title_41', { top: 170 }, 'move')
      tl.from('.car_model_41', { y: 500 }, 'move')
      tl.from('.img_box_41', { opacity: 0 }, 'move')
      tl.from('.font_box_41', { opacity: 0 }, 'move')
    },

    switchStation() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.img2_41',
          start: '1000px top',
          end: '1200px top',
          scrub: 0.1
        }
      })

      tl.addLabel('station')
      tl.to('.music_41', { top: -600, opacity: 0 }, 'station')
      tl.from('.station_41', { top: 600, opacity: 0 }, 'station')
      tl.to('.img2_41', { opacity: 1 }, 'station')

      tl.to('.car_model2_41', { opacity: 1 }, 'station')
    },

    switchGame() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.img3_41',
          start: '2000px top',
          end: '2400px top',
          scrub: 0.1
        }
      })

      tl.addLabel('game')
      tl.to('.station_41', { top: -600, opacity: 0 }, 'game')
      tl.from('.game_41', { top: 600, opacity: 0 }, 'game')
      tl.to('.img3_41', { opacity: 1 }, 'game')

      tl.to('.car_model2_41', { opacity: 0 }, 'game')
      tl.to('.car_model3_41', { opacity: 1 }, 'game')

      tl.to('.handle_img_41', { top: 600, opacity: 1 })
    },

    onLaunch() {
      this.pagePin()
      this.runAnimation()
      this.switchStation()
      this.switchGame()
    }
  }
}
</script>

<style lang="less" scoped>
.entertainment_space_41 {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #000;

  .content_box_41 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2560px;
    height: 1080px;
  }

  .title_41 {
    position: absolute;
    top: 320px;
    left: 846px;
    font-weight: 600;
    font-size: 140px;
    line-height: 197px;
    text-align: center;
    letter-spacing: 0.04em;
    margin: 0;

    background: linear-gradient(92.29deg, #DC79FF 0%, #256BFA 99.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .car_model_41 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2560px;
    height: 1080px;
    background-size: 2560px 1124px;
    background-position: center center;

    .car_model_item {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: 100%;
      opacity: 0;

      &.car_model1_41 {
        opacity: 1;
      }
    }
  }


  .font_box_41 {
    position: absolute;
    top: 262px;
    left: 758px;
    width: 262px;
    height: 556px;

    ul {
      position: absolute;
      top: 0;
      padding: 0;
      margin: 0;
    }
    
    .color1 {
      background: linear-gradient(94.88deg, #438EFF 1.12%, #838FFF 98.74%);
    }
    .color2 {
      background: linear-gradient(94.88deg, #436CFF 1.12%, #AE9AFF 98.74%);
    }
    .color3 {
      background: linear-gradient(94.88deg, #9D88FF 1.12%, #BC7EFF 98.74%);
    }
    .color4 {
      background: linear-gradient(94.88deg, #CE77FF 1.12%, #FF88D7 98.74%);
    }
    .color5 {
      background: linear-gradient(94.88deg, #FB995C 1.12%, #F3855D 98.74%);
    }
    .color6 {
      background: linear-gradient(94.88deg, #EC725E 1.12%, #E5625E 98.74%);
    }
    .color7 {
      background: linear-gradient(94.88deg, #DE4E5F 1.12%, #D63A60 98.74%);
    }
    .color8 {
      background: linear-gradient(94.88deg, #CB1E58 1.12%, #C70964 98.74%);
    }
    .color9 {
      background: linear-gradient(94.88deg, #FFD682 1.12%, #FFBC7A 98.74%);
    }
    .color10 {
      background: linear-gradient(94.88deg, #FFB979 1.12%, #FF996E 98.74%);
    }
    .color11 {
      background: linear-gradient(94.88deg, #FF9A6F 1.12%, #FF8267 98.74%);
    }

    .other_color {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    li {
      margin-bottom: 66px;
    }

    h5 {
      font-weight: 600;
      font-size: 46px;
      line-height: 65px;
      color: transparent;
      margin: 0
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #9e9e9e;
      margin: 4px 0 0 0;
    }
  }

  .img_box_41 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 199px), -50%);
    width: 800px;
    height: 500px;

    .car_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;

      &:nth-child(1) {
        opacity: 1;
      }
    }
  }

  .handle_img_41 {
    position: absolute;
    top: 1080px;
    left: 50%;
    transform: translateX(calc(-50% + 200px));
    width: 792px;
    height: 557px;
    background-size: 100% 100%;
    opacity: 0;
  }
}
</style>
