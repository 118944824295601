<template>
  <section class="control_33">
    <div class="control_box">
      <div class="font_box_33">
        <h3>多端互联互控</h3>
        <p>基于全自研的互联互通框架，手机与座舱之间可以完成高带宽、高稳定性、低延时、抗干扰的硬件相互调用。<br />支持手机端快捷寻车，支持视频会议时切换至车内摄像头，还能共享手机车机的流量</p>
      </div>

      <div class="car_bg car_link_33" bg-url="@/auto/assets/image/33/control.jpg" v-bg></div>
      <div class="car_bg find_car_33" bg-url="@/auto/assets/image/33/car_place.png" v-bg></div>

      <div class="hand_33">
        <div class="hand_box_33 hand1_33">
          <m-video class="find_video" poster="@/auto/assets/video/33/find_poster.jpg" src="@/auto/assets/video/33/find.mp4" width="234px" height="520px"
            muted ref="find_video"></m-video>
          <div class="hand_phone_bg" bg-url="@/auto/assets/image/33/hand_phone.png" v-bg></div>
        </div>

        <div class="hand_box_33 hand2_33" bg-url="@/auto/assets/image/33/phone_all.jpg" v-bg>
          <div class="hand_phone_bg" bg-url="@/auto/assets/image/33/hand_phone.png" v-bg></div>
        </div>
      </div>

      <div class="control_font_33">
        <h3>UWB 高精度寻车</h3>
        <p>停车后自动识别车位楼层、编号，并自动拍摄车辆四周照片，无网也能传输至手机实时查看；<br />采用 UWB 高精度定位与海拔高度双重指引，在没有信号的地下车库也能快速找车</p>
      </div>
    </div>
  </section>
</template>

<script>
import { videoPlayByVisibleArea } from "@/utils"

export default {
  name: 'Control',
  
  methods: {
    controlPin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.control_33',
          start: 'top top',
          end: '+=6000 top',
          pin: true,
          // markers: true
        }
      })
    },

    runControl() {
      const find_video = this.$refs.find_video

      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.font_box_33',
          start: 'top top',
          end: '4000px top',
          scrub: 0.1,
          // markers: true
        }
      })

      tl.addLabel('font')
      tl.to('.font_box_33', { top: 100, opacity: 0 }, 'font')
      tl.to('.car_link_33', { top: 0, opacity: 1, delay: 0.2 }, 'font')
      tl.to('.hand_33', { top: 202, x: 240, width: 431, height: 758, delay: 0.2 }, 'font')

      tl.addLabel('find_car')
      tl.to('.car_link_33', { top: -970, opacity: 0 }, 'find_car')
      tl.to('.find_car_33', {
        top: 0, opacity: 1,
        onStart: () => {
          find_video.currentTime = 0
          find_video.play()
        }
      }, 'find_car')
      tl.to('.hand_33', { top: 322, x: -200, delay: 0.2 }, 'find_car')
    },

    runFindCar() {
      const tl_1 = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.hand2_33',
          start: '3000px top',
          end: '3500px top',
          scrub: 0.1,
          // markers: true
        }
      })

      tl_1.addLabel('handle')
      tl_1.from('.hand1_33', { opacity: 0 }, 'handle')
      tl_1.to('.hand2_33', { opacity: 0 }, 'handle')

      tl_1.to('.control_font_33', { opacity: 1 })
    },

    onLaunch() {
      this.controlPin()
      this.runControl()
      this.runFindCar()
    }
  }
}
</script>

<style lang="less" scoped>
.control_33 {
  background-color: #17181C;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .control_box {
    width: 100vw;
    height: 900px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .font_box_33 {
    position: absolute;
    top: 293px;
    left: 50%;
    width: 447px;
    transform: translateX(calc(-50% - 280px));
    z-index: 1;
    
    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      color: #FFFFFF;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.6);
      margin: 8px 0 0 0;
    }
  }

  .car_bg {
    width: 100vw;
    height: 900px;
    position: absolute;
    top: 900px;
    background-size: 2560px 900px;
    background-position: center top;
    opacity: 0;
  }

  .find_car_33 {
    height: 900px;
  }

  .hand_33 {
    width: 506px;
    height: 890px;
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(calc(-50% + 300px));
  }
  .hand_box_33 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 55% 69%;
    background-repeat: no-repeat;
    background-position: 36% 4%;
  }

  .find_video {
    position: absolute;
    left: 71px;
    top: 10px;
  }

  .hand_phone_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }

  .control_font_33 {
    width: 792px;
    position: absolute;
    left: 50%;
    top: 140px;
    transform: translateX(-50%);
    opacity: 0;

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #fff;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);

      margin: 8px 0 0 0;
    }
  }
}
</style>
